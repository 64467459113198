import { is_order_ok, rep_is_order_ok, set_top_pick, rep_download_report, rep_get_reports, get_user_requests, rep_add_request, fill_out_prods, fetch_products, add_product_tag, remove_product_tag, set_cust_fave, set_cust_follow, get_customer_follows, get_customer_faves, get_brands, notification_prefs, change_password, is_token_ok, get_customer_stats, upload_catalogue, download_catalogue, download_bs, get_top_picks, update_product, get_cat_map, get_price_override, set_price_override, get_pricing_for_user, rep_get_orders, rep_place_order, rep_add_to_basket, rep_get_user_basket, get_user, load_customers, send_query, get_promotions, reset_password, forgot_password, get_products, get_token, get_basket, add_to_basket, update_basket_qty, search_products, set_favourite, get_follows, set_follow, get_stock_for_prods, signup, place_order, get_order_lines, get_orders } from './remote';

export const data = {

        products: {
            get: get_products,
            search: search_products,
            set_fave: set_favourite,
            following: get_follows,
            follow: set_follow,
            get_stock: get_stock_for_prods,
            get_promotions: get_promotions,
            get_cat_map: get_cat_map,
            get_top_picks: get_top_picks,
            get_brands: get_brands,
            fetch: fetch_products,
            fill_out: fill_out_prods
        },
        token: {
            get: get_token,
            is_ok: is_token_ok
        },
        basket: {
            get: get_basket,
            add: add_to_basket,
            update_qty: update_basket_qty
        },
        signup: {
            submit: signup
        },
        orders: {
            place: place_order,
            get: get_orders,
            get_lines: get_order_lines,
            is_ok: is_order_ok
        },
        user: {
            forgot: forgot_password,
            reset_pass: reset_password,
            send_query: send_query,
            load_customers: load_customers,
            get_user: get_user,
            change_password: change_password,
            notification_prefs: notification_prefs
        },
        rep: {
            get_user_basket: rep_get_user_basket,
            add_to_basket: rep_add_to_basket,
            place_order: rep_place_order,
            is_order_ok: rep_is_order_ok,
            get_orders: rep_get_orders,
            get_pricing: get_pricing_for_user,
            set_override: set_price_override,
            get_override: get_price_override,
            update_product: update_product,
            download_catalogue: download_catalogue,
            download_bs: download_bs,
            upload_catalogue: upload_catalogue,
            get_customer_stats: get_customer_stats,
            get_customer_follows: get_customer_follows,
            get_customer_faves: get_customer_faves,
            set_cust_fave: set_cust_fave,
            set_cust_follow: set_cust_follow,
            add_product_tag: add_product_tag,
            remove_product_tag: remove_product_tag,
            add_request: rep_add_request,
            get_user_requests: get_user_requests,
            set_top_pick: set_top_pick,
            reports: {
                get_list: rep_get_reports,
                download_report: rep_download_report
            }
        }
    
}

