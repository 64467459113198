import React, { useContext } from "react";
import Marquee from "react-marquee-slider";
import { AppContext } from "../context";
import { Link } from "react-router-dom";

const brands = [{
  brand: 'Aero',
  img: 'aero.jpg'
},{
  brand:'Alpen',
  img:'alpen.jpg'
},{
  brand:'Ambrosia',
  img: 'ambrosia.jpg'
},{
  brand: 'Astonish',
  img: 'astonish.jpg'
},{
  brand:'Baxters',
  img: 'baxters.jpg'
},{
  brand: 'Bisto',
  img: 'bisto.jpg'
},{
  brand:'Blue Dragon',
  img: 'blue_dragon.jpg'
},{
  brand: 'Branston',
  img: 'branston.jpg'
},{
  brand: 'Coca Cola',
  img: 'coca cola.jpg'
},{
  brand: 'Dolmio',
  img: 'dolmio.jpg'
},{
  brand: 'Evian',
  img: 'evian.jpg'
},{
  brand:'Fairy',
  img: 'fairy.jpg'
},{
  brand: 'Heinz',
  img: 'heinz.jpg'
}, {
  brand: 'Napolina',
  img: 'napolina.jpg'
},{
  brand: 'Nescafe',
  img:'nescafe.jpg',
},{
  brand: 'Nesquik',
  img: 'nesquik.jpg'
},{
  brand:'Old El Paso',
  img: 'old_el_paso.jpg'
},{
  brand: 'Oxo',
  img: 'oxo.jpg'
},{
  brand:'Pataks',
  img: 'pataks.jpg'
},{
  brand: 'Rowse',
  img:'rowse.jpg'
},{
  brand:'Twinings',
  img: 'twinings.jpg'
},{
  brand: 'Uncle Bens',
  img:'uncle.jpg'
}]

const Brands = () => {
  const { shop } = useContext(AppContext);

  const select = brand => {
    shop.product_filters.clear_multi('brands', () => {
      shop.product_filters.add_brand(brand);
    })
  }

  return (
    <div>
      <div className="marquee" style={{ height: 150 }}>
        <Marquee velocity={25}>
          {brands.map((i, k) => (
            <Link key={i} to='/shop'>
              <div onClick={() => select(i.brand)}>
                <img alt="Brand" src={require('../assets/logos/' + i.img)} className="marquee_brand_img" />
              </div>
            </Link>
          ))}
        </Marquee>
      </div>
    </div>
  )
}


export default Brands