import React, { useContext, useState } from 'react';
import { AppContext } from '../context';
import { Link } from 'react-router-dom';

const Basket = props => {
    const [showing, set_showing] = useState(false);
    const { basket, logged_in, strings } = useContext(AppContext);

    const toggle_show = () => set_showing(!showing);

    const sum_basket = () => {
        return basket.map(x => x.quantity).reduce((a,b) => a + b, 0);
    }

    if (logged_in) {
        return (
            <Link to='/basket' className="clear">
                <div onClick={toggle_show} className="button">
                    {sum_basket() + ' ' + strings['in-basket']}
                </div>
            </Link>
        )
    } else {
        return (
            <Link to='/login' className="clear">
                <div className="button">
                    Login
                </div>
            </Link>
        )
    }

}

export default Basket;