import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { AppContext } from '../context';
import { data } from '../dt';
import { Redirect, Link } from 'react-router-dom'

const LoginForm = props => {
    const { token_to_state, cookies_accepted, logged_in, strings, language } = useContext(AppContext);
    const [login_success, set_success] = useState(null);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async values => {
            const resp = await data.token.get(values.username, values.password, cookies_accepted);
            if (resp.success === true) {
                token_to_state(resp.user.token);
                set_success(true);
            } else {
                set_success(false);
            }
        },
        validate: values => {
            const errors = {};
            if (!values.username) {
                errors.username = strings['required']
            }
            if (!values.password) {
                errors.name = strings['required']
            }
            return errors;
        }
        })

    if (logged_in === true) {
        let url = new URL(window.location.href);
        let back_to = url.searchParams.get('frm');
        if (back_to === null) {
            return <Redirect to='/' />
        } else {
            return <Redirect to={'/' + back_to} />
        }
    }


    if (login_success === true) {
        return <Redirect to='/' />
    }

    const keypress = e => {
        if (e.key === 'Enter') {
            document.getElementById('submitButton').click();
        }
    }

    const passwordFocus = e => {
        document.getElementById('password').addEventListener('keypress', keypress)
    }

    const passwordBlur = e => {
        document.getElementById('password').removeEventListener('keypress', keypress)
        formik.handleBlur(e);
    }

    return (
        <form className="signup" onSubmit={formik.handleSubmit}>
            <h3>Login</h3>

            {props.did_reset === false ? 
                <div className="top_message">
                    {language === 'english' ? 
                        <p>No account yet? Click <Link to='/create_account' className="inline">here</Link> to sign up</p>
                    :
                        <p>Não tem conta ainda? <Link to='/create_account' className="inline">Clique aqui</Link> para criar uma conta</p>
                    }
                </div>
            :
                
                <div className="top_message">
                    {strings['success-reset']}
                </div>
            }
            <label htmlFor="username" className="required">Username</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="username"
                id="username"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.username}
                />
            {formik.touched.username && formik.errors.username ? (
                    <div className="error">{formik.errors.username}</div>
                ) : null}

            <label htmlFor="password" className="required">{strings['password']}</label>
            <input
                name="password"
                onFocus={passwordFocus}
                id="password"
                type="password"
                onBlur={passwordBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                ) : null}

            {login_success === false && <div className="error">Invalid Credentials</div>}

            <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'center'}}>
                <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end', alignItems:'center', width: 500}}>
                    <Link to='forgot' className="clear">
                        <div className="button">
                            {strings['forgot-password']}
                        </div>
                    </Link>
                    <input style={{display: 'none'}} id='login_submit' className="submit" type="submit" value="Login" />
                    <label id="submitButton" htmlFor='login_submit'>
                        <div className="button" htmlFor='login_submit'>Login</div>
                    </label>
                </div>
            </div>

        </form>
    )
}

const LoginScreen = props => {
    const [did_reset, set_did_reset] = useState(false);
    const [did_create, set_did_create] = useState(false);

    const get_params = () => {
        let url = new URL(window.location.href);
        let did_reset = url.searchParams.get('did_reset');
        let did_create = url.searchParams.get('create');
        set_did_reset(did_reset === '1');
        set_did_create(did_create === '1');
    }

    useEffect(() => {
        get_params();
    })

    return (
        <div className="container">
            <LoginForm did_reset={did_reset} did_create={did_create} />
        </div>
    )
}

export default LoginScreen;