import React, { useContext, useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { AppContext } from '../context';
import { MdClose } from 'react-icons/md';
import { Field, Formik, Form } from 'formik';


const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99999
    },
    overlay: {
        zIndex: 8000,
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
}


const TagsBox = props => {
    const { rep } = useContext(AppContext);
    const [s_input, set_input] = useState('');
    const [s_tags, set_tags] = useState([]);
    const { id } = props;
    const {prod_edit_modal} = rep;
    const [tick, set_tick] = useState(0);
    const { tags } = rep;
    const ip = useRef(null);

    useEffect(() => {
        let ttags = [];
        if (id in tags) {
            ttags = tags[id];
        }
        set_tags(ttags);
    }, [tags, id, tick, prod_edit_modal.id])




    useEffect(() => {
        const add_from_enter = (e) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                if (e.target.value) {
                    const tag = e.target.value.toLowerCase();
                    if (tag.length > 0) {
                        set_input('');
                        rep.add_product_tag(prod_edit_modal.id, tag);
                    }
                }
            }
            if (e.keyCode === 9) {
                props.next_prod(e);
            }
        }

        const tgt = ip.current;
        tgt.addEventListener("keydown", add_from_enter);
        return () => {
            tgt.removeEventListener("keydown", add_from_enter);
        }
    });
    
    const press_button = () => {
        const tag = s_input.toLowerCase();
        if (tag.length > 0) {
            set_input('');
            rep.add_product_tag(prod_edit_modal.id, tag);
        }
    }

    const remove_tag = (tag) => {
        rep.remove_product_tag(id, tag, () => set_tick(tick + 1));
    }

    return (
        <div className="tags_box">
            <span className="title">Tags</span>
            <div className="input_row">
                <input id="tagInput" ref={ip} value={s_input} onChange={e => set_input(e.target.value)} className="tag_input" />
                <button type="button" onClick={press_button} className="button">Add</button>
            </div>
            <div className="tags_holder">
                {s_tags.map((t,i) => <div key={i} className="tag_bit"><div className="value">{t}</div><div onClick={() => remove_tag(t)} className="delete"><MdClose size={20} /></div></div>)}
            </div>
        </div>
    )
}


const ProdModal = props => {
    const { rep, category_map, strings} = useContext(AppContext);
    const [subs, set_subs] = useState([]);
    const { prod_edit_modal } = rep;
    const [s_values, set_s_values] = useState(null);
    const [refresh, set_refresh] = useState(false);

    const next_prod = (e) => {
        if (e.keyCode === 39) {
            e.stopPropagation();
            e.preventDefault();
            set_refresh(true);
            rep.update_product(prod_edit_modal.id, s_values.description, s_values.size, s_values.base_price, s_values.category, () => {
                rep.edit_next_prod(1, () => set_refresh(false));
            })
        } else if (e.keyCode === 37) {
            e.stopPropagation();
            e.preventDefault();
            set_refresh(true);
            rep.update_product(prod_edit_modal.id, s_values.description, s_values.size, s_values.base_price, s_values.category, () => {
                rep.edit_next_prod(-1, () => set_refresh(false));
            })
        }
    }

    const next_prod_tab = e => {
        if (e.keyCode === 9) {
            e.stopPropagation();
            e.preventDefault();
            set_refresh(true);
            rep.update_product(prod_edit_modal.id, s_values.description, s_values.size, s_values.base_price, s_values.category, () => {
                rep.edit_next_prod(1, () => set_refresh(false))
            })
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', next_prod)
        return () => window.removeEventListener('keyup', next_prod);
    })

    useEffect(() => {
        let out = [];
        for (var i=0; i < category_map.length; i++) {
            if (category_map[i].label !== 'All Products') {
                for (var j=0; j < category_map[i].options.length; j++) {
                    out.push({label: category_map[i].label + ' / ' + category_map[i].options[j].label, value:  category_map[i].label + '/' + category_map[i].options[j].label})
                }
            }
        }
        set_subs(out);
    }, [category_map])

    const close = () => {
        rep.show_modal_prod(null);
    }

    useEffect(() => {
        if (prod_edit_modal.id) {
            set_s_values({
                description: prod_edit_modal.description,
                size: prod_edit_modal.size,
                case: prod_edit_modal.case,
                base_price: prod_edit_modal.base_price,
                category: prod_edit_modal.category_1 + '/' + prod_edit_modal.category_2
            })
        }
    }, [prod_edit_modal.id, prod_edit_modal])

    return (
        <Modal  
            isOpen={prod_edit_modal !== null}
            onRequestClose={close}
            style={style}
            contentLabel="Edit Product"
        >
            {prod_edit_modal !== null && <h3>Editing {prod_edit_modal.description} (ALB: {prod_edit_modal.albcode}, WEB: {prod_edit_modal.id})</h3>}
            {refresh === true ? null : 
                <Formik
                    initialValues={{
                        description: prod_edit_modal.description,
                        size: prod_edit_modal.size,
                        case: prod_edit_modal.case,
                        base_price: prod_edit_modal.base_price,
                        category: prod_edit_modal.category_1 + '/' + prod_edit_modal.category_2
                    }}
                    onSubmit={values => {
                        rep.update_product(prod_edit_modal.id, values.description, values.size, values.base_price, values.category, () => {
                            close();
                        })
                    }}
                    validateOnChange={true}
                    validate={values =>set_s_values(values)}
                    >
                    <Form className="signup contact">
                        <label htmlFor="description" className="required">{strings['name']}</label>
                        <Field
                            name="description"
                            id="description"
                            type="text"
                            />


                        <label htmlFor="size" className="required">{strings['size']}</label>
                        <Field
                            name="size"
                            id="size"
                            type="text"
                            />


                        <label htmlFor="base_price" className="required">{strings['base-price']}</label>
                        <Field
                            name="base_price"
                            id="base_price"
                            type="text"
                            />

                        <label htmlFor="category" className="required">{strings['category']}</label>
                        <Field className="form_select" as="select" name="category">
                            {subs.map((s,i) => <option key={i} value={s.value}>{s.label}</option>)}
                        </Field>

                        <div style={{display: 'flex', flexDirection:'row', width: 200, alignItems:'center', justifyContent: 'flex-start', marginTop:15, textAlign:'left'}}>
                            <input onChange={e => rep.set_top_pick(prod_edit_modal.id, e.target.checked)} type='checkbox' checked={prod_edit_modal.is_top_pick === true} />
                            <span>Top Pick?</span>
                        </div>

                        <TagsBox next_prod={next_prod_tab} id={prod_edit_modal.id} tags={prod_edit_modal.tags} />

                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'center'}}>
                            <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end', alignItems:'center', width: 500}}>
                                <input style={{display: 'none'}} id='login_submit' className="submit" type="submit" />
                                <label htmlFor='login_submit'>
                                    <div className="button" htmlFor='login_submit'>Submit</div>
                                </label>
                            </div>
                        </div>
                    </Form>
                </Formik>
            }

        </Modal>

    )
}

export default ProdModal