import { api } from '../utils/api';
import { Cookies } from 'react-cookie-consent';
import { api_url } from '../config'


export const get_products = async (token, offset, sort_by, get, in_stock, faves_only, prom_id, category, brand) => {
    const body = {action: 'get_products', from: offset, num: get, sort_by, in_stock, faves_only, prom_id, category, brand}
    const response = await api(body, () => {}, '', {Authorization: token});
    const { products, count } = response;
    return {products, count};
}


export const search_products = async (token, term, offset, sort_by, get, in_stock, faves_only, prom_id=null, category, brand) => {
    const body = {action: 'search_products', offset: offset, num: get, sort_by, term:term, in_stock, faves_only, prom_id, category, brand}
    const response = await api(body, () => {}, '', {Authorization: token});
    
    const { products, count } = response;
    return {products, count};
}

export const get_token = async (username, password) => {
    const response = await api({action: 'login', username, password});
    if (response.success) {
        const can_save = Cookies.get('accept_cookie');
        if (can_save === 'true') {
            Cookies.set('token', response.user.token, {path: '/', secure: true, sameSite: 'strict'});
        }
    }
    return response;
}

export const get_user = async (token, callback=() => {}) => {
    const response = await api({action: 'token_login'}, () => {}, '', {Authorization: token});
    callback(response.user);
    return response.user;
}

export const add_to_basket = async (token, product_id, qty, callback=() => {}) => {
    const response = await api({action: 'add_to_basket', product_id, qty}, () => {}, '', {Authorization: token});
    const { basket, override, new_prod } = response;
    callback({basket, override, new_prod});
    return { basket, override, new_prod };
}


export const rep_add_to_basket = async (token, user_id, product_id, qty, callback=() => {}) => {
    const response = await api({action: 'add_to_user_basket', product_id, user_id, qty}, () => {}, '', {Authorization: token});
    const { basket, override, new_prod } = response;
    callback({ basket, override, new_prod })
    return { basket, override, new_prod };
}

export const get_basket = async (token, callback=() => {}) => {
    const response = await api({action: 'get_basket'}, () => {}, '', {Authorization: token});
    const basket = response.basket;
    callback(basket);
    return basket;
}

export const update_basket_qty = async (token, product_id, qty, callback=() => {}) => {
    const response = await api({action: 'set_basket', product_id, qty}, () => {}, '', {Authorization: token});
    const basket = response.basket;
    callback(basket);
    return basket;
}


export const set_favourite = async (token, product_id, is_fave, callback=() => {}) => {
    await api({action: 'favourite', product_id, is_fave}, () => {}, '', {Authorization: token});
    callback(true)
    return true;
}

export const get_follows = async (token, callback=() => {}) => {
    const response = await api({action: 'get_follows'}, () => {}, '', {Authorization: token});
    callback(response.following);
    return response.following;
}

export const set_follow = async (token, should_follow, prod_id) => {
    const response = await api({action: 'toggle_follow', follow: should_follow, prod_id}, () => {}, '', {Authorization: token});
    return response.success;
}


export const get_stock_for_prods = async (token, codes) => {
    const response = await api({action: 'update_stock', codes: codes}, () => {}, '', {Authorization: token});
    return response.data;
}

export const signup = async (data, callback) => {
    const response = await fetch(api_url + '/signup', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });
    const rdata = await response.json()
    callback(rdata);
}

export const place_order = async (token) => {
    const response = await api({action:'place_order'}, () => {}, '', {Authorization: token});
    const { success, order, amendments } = response;
    return { success, order, amendments };
}

export const rep_place_order = async(token, user_id) => {
    const response = await api({action: 'place_user_order', user_id}, () => {}, '', {Authorization: token});
    const { success, order, amendments } = response;
    return {
        success,
        order,
        amendments
    }
}

export const get_orders = async (token, offset) => {
    const response = await api({action:'get_orders', offset}, () => {}, '', {Authorization: token});
    const { orders, count } = response;
    return { orders, count }
}

export const rep_get_orders = async (token, user_id, offset) => {
    const response = await api({user_id, offset, action: 'get_user_orders'}, () => {}, '', {Authorization: token});
    const { orders, count } = response;
    return { orders, count }
}

export const get_order_lines = async (token, order_id) => {
    const response = await api({action: 'get_order_lines', order_id: order_id}, () => {}, '', {Authorization: token});
    const order = response.order;
    return order;
}

export const forgot_password = async (username, callback=() => {}) => {
    const response = await api({action: 'forgot_password', username});
    callback(response.success);
    return response.success;
}

export const reset_password = async (token, password, callback=() => {}) => {
    const response = await fetch(api_url + '/set_pass', {
        method:'POST',
        headers: {Accept: 'application/json', 'Content-Type': 'application/json', Authorization: token},
        body:JSON.stringify({
            set_to: password
        })
    });
    const data = await response.json();
    callback(data.success);
} 


export const get_promotions = async (token, callback=() => {}) => {
    const response = await api({action:'get_proms'}, () => {}, '', {Authorization: token});
    callback(response.proms);
    return response.proms;
}

export const send_query = async (name, email, phone, message) => {
    const response = await api({action: 'have_a_question', name, email, phone, message});
    return response.success;
}



//REP

export const load_customers = async (token, callback=() => {}) => {
    const response = await api({action: 'get_customers'}, () => {}, '', {Authorization: token});
    callback(response.customers);
    return response.customers;
}

export const rep_get_user_basket = async (token, user_id, callback=() => {}) => {
    const response = await api({action: 'get_user_basket', user_id}, () => {}, '', {Authorization: token});
    const basket = response.basket;
    callback(basket)
    return basket;
}

export const get_pricing_for_user = async (token, target_id, codes, callback=() => {}) => {
    const response = await api({action: 'get_pricing_for_user', target: target_id, codes}, () => {}, '', {Authorization: token});
    const pricing = response.pricing;
    callback(pricing);
    return pricing;
}

export const get_price_override = async (token, customer_id, callback=() => {}) => {
    const response = await api({action: 'get_price_override', target: customer_id}, () => {}, '', {Authorization: token});
    callback(response.override);
    return response.override;
}

export const set_price_override = async (token, customer_id, override, callback=() => {}) => {
    const response = await api({action: 'set_price_override', target: customer_id, override}, () => {}, '', {Authorization: token});
    callback(response.success);
    return response.success;
}

export const get_cat_map = async (callback=() => {}) => {
    const response = await api({action: 'get_cats'});
    callback(response.cats);
    return response.cats;
}

export const update_product = async (token, prod_id, description, size, base_price, category, callback=() => {}) => {
    const cats = category.split('/');
    const category_1 = cats[0];
    const category_2 = cats[1];
    const response = await api({action: 'update_product', prod_id, description, size, base_price, category_1, category_2}, () => {}, '', {Authorization: token});
    callback(response.product);
    return response.product;
}

export const get_top_picks = async (token, callback=() => {}) => {

    const rq = {action: 'get_top_picks', limit: 5};

    const response = await api(rq, () => {}, '', {Authorization: token});

    callback(response.products);
    return response.products;
}

export const download_catalogue = async (token) => {
    const response = await fetch(api_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            action: 'download_catalogue'
        })
    });

    const cata = await response.blob();
    var url = window.URL.createObjectURL(cata);
    var a = document.createElement('a');
    a.href = url;
    a.download = "Catalogue_Export.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export const download_bs = async (token) => {
    const response = await fetch(api_url, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            action:'download_bs'
        })
    });

    const bs = await response.blob();
    var url = window.URL.createObjectURL(bs);
    var a = document.createElement('a');
    a.href = url;
    a.download = "Bulk_Stock.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export const upload_catalogue = async(token, file, cb) => {
    let fd = new FormData();
    fd.append('file', file);
    const response = await fetch(api_url + '/upload_cat', {
        method: 'POST',
        headers: {Authorization: token},
        body: fd
    });

    if (response.headers.get('content-type') === 'application/json') {
        const data = await response.json();
        cb(data.success, false);
    } else {
        const rejects = await response.blob();
        var url = window.URL.createObjectURL(rejects);
        var a = document.createElement('a');
        a.href = url;
        a.download = "Rejects.xlsx";
        document.body.appendChild(a);
        a.click()
        a.remove();
        cb(true, true);
    }
}

export const get_customer_stats = async (token, cust_id) => {
    const response = await api({action: 'get_customer_stats', cust_id}, () => {}, '', {Authorization: token});
    return response.stats;
}

export const is_token_ok = async (token, callback=() => {}) => {
    const response = await api({'action': 'is_token_ok'}, () => {}, '', {Authorization: token});
    callback(response.is_ok);
    return response.is_ok;
}

export const change_password = async(token, current_pass, new_pass, callback) => {
    const response = await api({'action': 'change_password', current_password: current_pass, new_password: new_pass}, () => {}, '', {Authorization: token});
    callback(response.success);
    return response.success;
}

export const notification_prefs = async (token, accept_emails, callback=() => {}) => {
    const response = await api({action: 'notification_prefs', accept_emails}, () => {}, '', {Authorization: token})
    callback(response.success);
    return response.success;
}

export const get_brands = async (callback=() => {}) => {
    const response = await api({action: 'get_brands'});
    callback(response.brands);
    return response.brands;
}

export const get_customer_faves = async (token, user_id, callback=() => {}) => {
    const response = await api({action: 'get_customer_faves', user_id}, () => {}, '', {Authorization: token})
    const faves = response.faves;
    callback(faves);
    return faves;
}

export const get_customer_follows = async (token, user_id, callback=() => {}) => {
    const response = await api({action: 'get_customer_follows', user_id}, () => {}, '', {Authorization: token});
    const follows = response.follows;
    callback(follows);
    return follows;
}

export const set_cust_fave = (token, user_id, product_id, should_fave) => {
    api({action: 'set_cust_fave', is_fave: should_fave, prod_id: product_id, cust_id: user_id}, () => {}, '', {Authorization: token});
}

export const set_cust_follow = (token, user_id, product_id, should_follow) => {
    api({action: 'set_cust_follow', follow: should_follow, prod_id: product_id, cust_id: user_id}, () => {}, '', {Authorization: token});
}

export const add_product_tag = async (token, product_id, tag) => {
    api({action: 'add_tag', product_id, tag}, () => {}, '', {Authorization: token});
}

export const remove_product_tag = async (token, product_id, tag) => {
    api({action: 'remove_tag', product_id, tag}, () => {}, '', {Authorization: token});

}


export const rep_add_request = async (token, product_id, user_id, quantity, price) => {
    const response = await api({action: 'add_request', product_id, user_id, quantity, price}, () => {}, '', {Authorization: token});
    return response.request;
}

export const get_user_requests = async (token, user_id) => {
    const response = await api({action: 'get_user_requests', user_id}, () => {}, '', {Authorization: token});
    return response.requests;
}

export const fetch_products = async (token, offset, limit, filters) => {
    const response = await api({action: 'fetch_prods', offset, limit, filters}, () => {}, '', {Authorization: token});
    const { products, count } = response;
    return {products, count}
}

export const fill_out_prods = async (token, ids) => {
    const response = await api({action: 'fill_products', ids}, () => {}, '', {Authorization: token});
    const { products } = response;
    return products;
}

export const rep_get_reports = async (token, callback=() => {}) => {
    const response = await api({action: 'get_reports_list'}, () => {}, '', {Authorization: token});
    const { reports } = response;
    callback(reports);
    return reports;
}

export const set_top_pick = async (token, prod_id, top_pick, callback=() => {}) => {
    const response = await api({action: 'set_top_pick', prod_id, top_pick}, () => {}, '', {Authorization: token});
    const { success } = response;
    callback(success)
    return success
}


export const rep_download_report = async (token, key, callback, name, extraData) => {
    const response = await fetch(api_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            action: 'download_report',
            report: key,
            extraData
        })
    });

    const report = await response.blob();
    var url = window.URL.createObjectURL(report);
    var a = document.createElement('a');
    a.href = url;
    a.download = name + ".xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
    callback();
}

export const is_order_ok = async (token, make_amendments=false, callback=() => {}) => {
    const response = await api({action: 'is_order_ok', make_amendments}, () => {}, '', {Authorization: token});
    const { success, is_ok, issues } = response;
    callback(is_ok, issues);
    return {
        is_ok, issues
    }
}

export const rep_is_order_ok = async (token, user_id, make_amendments=false, callback=() => {}) => {
    const response = await api({action: 'is_user_order_ok', user_id, make_amendments}, () => {}, '', {Authorization:token});
    const { success, is_ok, issues } = response;
    callback(is_ok, issues);
    return {
        is_ok, issues
    }
}