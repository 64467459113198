import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../context';
import { Link, useLocation } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';


const Search = props => {
    const [term, set_term] = useState('');
    const { shop, strings, rep } = useContext(AppContext);
    const route = useLocation();
    const ip = useRef(null);
    let { search, clear_search } = shop.product_filters;

    const search_on_enter = (e) => {
        if (e.keyCode === 13) {
            //search(e.target.value);
            document.getElementById('search_button').click();
            e.preventDefault();
        }
    }

    useEffect(() => {
        const tgt = ip.current;
        tgt.addEventListener("keydown", search_on_enter);
        return () => {
            tgt.addEventListener("keydown", search_on_enter);
        }
    }, []);
    

    const do_search = () => {
        search(term);
    }

    const button = () => {
        if (route.pathname !== '/shop' && route.pathname !== '/ukstock') {
            return (
                <Link to='/shop' className="clear">
                    <div id="search_button" onClick={do_search} className="button secondary search"><FaSearch className="search_icon" color="#fff" /></div>
                </Link>
            )
        } else {
            return (
                <div id="search_button" onClick={do_search} className="button secondary search"><FaSearch className="search_icon" color="#fff" /></div>
            )
        }
    }

    return(
        <div className="search_bar">
            <input ref={ip} placeholder={strings['product-search']} id="search_text" className="search_input" value={term} onChange={e => set_term(e.target.value)} />
            {button()}
            {shop.in_search && <div className="button search clear" onClick={() => { clear_search(); set_term('')}}>{strings['clear']}</div>}
        </div>
    );
}

export default Search;