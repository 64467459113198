import React, { useContext } from 'react';
import Modal from 'react-modal';
import { AppContext } from '../context';

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        zIndex: 8000,
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
}

const OOSModal = props => {
    const { product } = props;
    const { following, follow, strings } = useContext(AppContext);

    let is_following = false;
    if (product !== null) {
        is_following = following.indexOf(product.id) !== -1;
    }

    return (
        <Modal  
            isOpen={product !== null}
            onRequestClose={props.close}
            style={style}
            contentLabel="Sorry"
        >
            <h3>{strings['sorry']}</h3>{/*MISSING*/}
            <h4>{strings['this-product-is-out']}</h4>{/*MISSING*/}
            <p>{strings['we-can-let-you']}</p>{/*MISSING*/}
            <div className="oos_modal_row">
                {strings['notify-me']}
                <input className="checkbox" type="checkbox" checked={is_following} onClick={() => follow(product.id, !is_following)} />
            </div>

            <div style={{flexDirection:'row', display:'flex', justifyContent:'flex-end', marginTop: 20}}>
                <div onClick={props.close} className="secondary_button" style={{height: 20, cursor: 'pointer'}}>OK</div>
            </div>

        </Modal>

    )
}

export default OOSModal