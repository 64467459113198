import React, { useContext } from 'react';
import { AppContext } from '../context';

const TermsScreen = props => {
    const { language } = useContext(AppContext);
    if (language === 'portuguese') {
        return (
            <div className="container">
                <div className="signup">
    
                <h2><strong>Termos e Condições</strong></h2>
    
                    <p>Bem-vindo a Albany Portugal!</p>
    
                    <p>Estes termos e condições definem as regras e regulamentos de utilização do Website da Albany Products Ltd, localizado em www.albanyportugal.com.</p>
    
                    <p>Ao aceder a este site, presumimos que você aceita estes termos e condições. Não continue a usar o site de Albany Portugal se não concordar com todos os termos e condições indicados nesta página.</p>
    
                    <p>A terminologia a seguir aplica-se a estes Termos e Condições, Declaração de Privacidade e Aviso de Isenção de Responsabilidade e todos os Acordos: "Cliente", "Você" e "Seu" referem-se a você, a pessoa que faz login neste site e está em conformidade com os termos e condições da Empresa. "A Empresa", "Nós", "Nós", "Nosso" e "Nós" referem-se à nossa Empresa. "Parte", "Partes" ou "Nós" referem-se ao Cliente e a nós mesmos. Todos os termos referem-se à oferta, aceitação e consideração do pagamento necessário para realizar o processo de nossa assistência ao Cliente da maneira mais adequada para o propósito expresso de atender às necessidades do Cliente no que diz respeito à prestação dos serviços declarados da Empresa, de acordo com e sujeito à legislação vigente dos Países Baixos. Qualquer uso da terminologia acima ou outras palavras no singular, plural, maiúsculas e / ou ele / ela, são considerados intercambiáveis e, portanto, como referindo-se aos mesmos.</p>
    
                    <h3><strong>Cookies</strong></h3>
    
                    <p>Empregamos o uso de cookies. Ao aceder a Albany Portugal, você concorda em usar cookies de acordo com a Política de Privacidade da Albany Products Ltd.</p>
    
                    <p>A maioria dos sites interativos usa cookies para nos permitir recuperar os detalhes do utilizador para cada visita. Os cookies são usados pelo nosso site para habilitar a funcionalidade de certas áreas para tornar mais fácil para as pessoas que visitam o nosso site. Alguns de nossos afiliados / parceiros de publicidade também podem usar cookies.</p>
    
                    <h3><strong>Licença</strong></h3>
    
                    <p>Salvo indicação em contrário, Albany Products Ltd e / ou seus licenciados detêm os direitos de propriedade intelectual de todo o material no site de Albany Portugal. Todos os direitos de propriedade intelectual são reservados. Você pode aceder este site de Albany Portugal para seu uso pessoal sujeito às restrições definidas nestes termos e condições.</p>
    
                    <p>Você não deve:</p>
                    <ul>
                        <li>Republicar material de Albany Portugal</li>
                        <li>Vender, alugar ou sublicenciar material de Albany Portugal</li>
                        <li>Reproduzir, duplicar ou copiar material de Albany Portugal</li>
                        <li>Redistribuir o conteúdo de Albany Portugal</li>
                    </ul>
    
                    <p>Este Acordo terá início nesta data currente.</p>
    
                    <h3><strong>Hiperligações para nosso conteúdo</strong></h3>
    
                    <p>As seguintes organizações podem ligar-se ao nosso site sem aprovação prévia por escrito:</p>
    
                    <ul>
                        <li>Agências governamentais</li>
                        <li>Motores de busca</li>
                        <li>Organizações de notícias</li>
                        <li>Os distribuidores de diretórios online podem ter links para o nosso site da mesma maneira que fazem um hiperlink para sites de outras empresas listadas</li>
                        <li>Empresas credenciadas em todo o sistema, exceto a solicitação de organizações sem fins lucrativos, centros de caridade e grupos de arrecadação de fundos de caridade que não podem ter um link para o nosso site.</li>
                    </ul>
    
                    <p>Essas organizações podem ter links para a nossa página inicial, publicações ou outras informações do site, desde que o link: (a) não seja de forma alguma enganoso; (b) não implique falsamente em patrocínio, endosso ou aprovação da parte vinculante e de seus produtos e / ou serviços; e (c) enquadra-se no contexto do site da parte vinculante.</p>
    
                    <p>Podemos considerar e aprovar outras solicitações de link dos seguintes tipos de organizações:</p>
    
                    <ul>
                        <li>fontes de informações de consumidores e / ou negócios comumente conhecidos</li>
                        <li>sites da comunidade dot.com</li>
                        <li>associações ou outros grupos que representam instituições de caridade</li>
                        <li>distribuidores de diretórios online</li>
                        <li>portais de internet</li>
                        <li>escritórios de contabilidade, advocacia e consultoria</li>
                        <li>instituições educacionais e associações comerciais</li>
                    </ul>
    
                    <p>Aprovaremos as solicitações de link dessas organizações se decidirmos que: (a) o link não nos faria parecer desfavoravelmente para nós mesmos ou para nossos negócios credenciados; (b) a organização não possui registos negativos connosco; (c) o benefício para nós da visibilidade do hiperlink compensa a ausência de Albany Products Ltd; e (d) o link está no contexto de informações gerais de recursos.</p>
    
                    <p>Estas organizações podem ter um link para a nossa página inicial, desde que o link: (a) não seja de forma alguma enganoso; (b) não implique falsamente em patrocínio, endosso ou aprovação da parte vinculante e de seus produtos ou serviços; e (c) enquadra-se no contexto do site da parte vinculante.</p>
    
                    <p>Se você for uma das organizações listadas no parágrafo 2 acima e estiver interessado em criar um link para o nosso site, deve nos informar enviando um e-mail para Albany Products Ltd. Por favor, inclua o seu nome, o nome da sua organização, informações de contato, bem como o URL do seu site, uma lista de todos os URLs dos quais você pretende vincular-se ao nosso site e uma lista dos URLs do nosso site aos quais deseja vincular-se. Espere 2 a 3 semanas por uma resposta.</p>
    
                    <p>As organizações aprovadas podem fazer um hiperlink para o nosso site da seguinte forma:</p>
    
                    <ul>
                        <li>Usando nosso nome corporativo; ou</li>
                        <li>Pelo uso do localizador uniforme de recursos ao qual está vinculado; ou</li>
                        <li>Pelo uso de qualquer outra descrição do nosso site vinculado que faça sentido dentro do contexto e formato do conteúdo no site da parte vinculante.</li>
                    </ul>
    
                    <p>Nenhum uso do logotipo de Albany Products Ltd ou outro trabalho artístico será permitido para vinculação sem um contrato de licença de marca comercial.</p>
    
                    <h3><strong>iFrames</strong></h3>
    
                    <p>Sem a aprovação prévia e permissão por escrito, você não pode criar quadros em torno de nossas páginas da Web que alterem de qualquer forma a apresentação visual ou aparência de nosso site.</p>
    
                    <h3><strong>Responsabilidade de Conteúdo</strong></h3>
    
                    <p>Não seremos responsáveis por qualquer conteúdo que apareça em seu site. Você concorda em nos proteger e nos defender contra todas as reivindicações que estejam surgindo em seu site. Nenhum link (s) deve aparecer em qualquer site que possa ser interpretado como calunioso, obsceno ou criminoso, ou que infrinja, de outra forma viole ou defenda a violação ou outra violação de quaisquer direitos de terceiros.</p>
    
                    <h3><strong>Sua privacidade</strong></h3>
    
                    <p>Leia a Política de Privacidade</p>
    
                    <h3><strong>Reserva de direitos</strong></h3>
    
                    <p>Reservamo-nos o direito de solicitar que você remova todos os links ou qualquer link específico para o nosso site. Você aprova a remoção imediata de todos os links para nosso site, mediante solicitação. Também nos reservamos o direito de amenizar estes termos e condições e sua política de vinculação a qualquer momento. Ao ligar-se continuamente ao nosso site, você concorda em comprometer-se e seguir estes termos e condições de vinculação.</p>
    
                    <h3><strong>Remoção de links do nosso site</strong></h3>
    
                    <p>Se você encontrar algum link no nosso site que seja ofensivo por qualquer motivo, você está à vontade para entrar em contato e informar-nos a qualquer momento. Consideraremos as solicitações de remoção de links, mas não somos obrigados a fazê-lo ou responder diretamente a você.</p>
    
                    <p>Não garantimos que as informações neste site sejam corretas, não garantimos a sua integridade ou precisão; nem prometemos garantir que o site permaneça disponível ou que o material no site seja mantido atualizado.</p>
    
                    <h3><strong>Aviso Legal</strong></h3>
    
                    <p>Na extensão máxima permitida pela lei aplicável, excluímos todas as representações, garantias e condições relacionadas ao nosso site e ao uso deste site. Nada neste aviso irá:</p>
    
                    <ul>
                        <li>limitar ou excluir a nossa responsabilidade ou a sua responsabilidade por morte ou danos pessoais;</li>
                        <li>limitar ou excluir a nossa responsabilidade ou a sua responsabilidade por fraude ou deturpação fraudulenta;</li>
                        <li>limitar qualquer uma de nossas responsabilidades de qualquer forma que não seja permitida pela lei aplicável; ou</li>
                        <li>excluir qualquer uma das nossas responsabilidades ou responsabilidades que não possam ser excluídas pela legislação aplicável.</li>
                    </ul>
    
                    <p>As limitações e proibições de responsabilidade estabelecidas nesta Seção e em outras partes desta isenção de responsabilidade: (a) estão sujeitas ao parágrafo anterior; e (b) rege todas as responsabilidades decorrentes da isenção de responsabilidade, incluindo responsabilidades decorrentes de contratos, atos ilícitos e por violação de dever estatutário.</p>
    
                    <p>Enquanto o site e as informações e serviços nele contidos forem fornecidos gratuitamente, não seremos responsáveis por quaisquer perdas ou danos de qualquer natureza.</p>
                </div>
            </div>
        )
    }
    return (
        <div className="container">
            <div className="signup">

            <h2><strong>Terms and Conditions</strong></h2>

                <p>Welcome to Albany Portugal!</p>

                <p>These terms and conditions outline the rules and regulations for the use of Albany Products Ltd's Website, located at www.albanyportugal.com.</p>

                <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Albany Portugal if you do not agree to take all of the terms and conditions stated on this page.</p>

                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                <h3><strong>Cookies</strong></h3>

                <p>We employ the use of cookies. By accessing Albany Portugal, you agreed to use cookies in agreement with the Albany Products Ltd's Privacy Policy. </p>

                <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

                <h3><strong>License</strong></h3>

                <p>Unless otherwise stated, Albany Products Ltd and/or its licensors own the intellectual property rights for all material on Albany Portugal. All intellectual property rights are reserved. You may access this from Albany Portugal for your own personal use subjected to restrictions set in these terms and conditions.</p>

                <p>You must not:</p>
                <ul>
                    <li>Republish material from Albany Portugal</li>
                    <li>Sell, rent or sub-license material from Albany Portugal</li>
                    <li>Reproduce, duplicate or copy material from Albany Portugal</li>
                    <li>Redistribute content from Albany Portugal</li>
                </ul>

                <p>This Agreement shall begin on the date hereof.</p>

                <h3><strong>Hyperlinking to our Content</strong></h3>

                <p>The following organizations may link to our Website without prior written approval:</p>

                <ul>
                    <li>Government agencies;</li>
                    <li>Search engines;</li>
                    <li>News organizations;</li>
                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                    <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                </ul>

                <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

                <p>We may consider and approve other link requests from the following types of organizations:</p>

                <ul>
                    <li>commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>associations or other groups representing charities;</li>
                    <li>online directory distributors;</li>
                    <li>internet portals;</li>
                    <li>accounting, law and consulting firms; and</li>
                    <li>educational institutions and trade associations.</li>
                </ul>

                <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Albany Products Ltd; and (d) the link is in the context of general resource information.</p>

                <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

                <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Albany Products Ltd. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

                <p>Approved organizations may hyperlink to our Website as follows:</p>

                <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                </ul>

                <p>No use of Albany Products Ltd's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

                <h3><strong>iFrames</strong></h3>

                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

                <h3><strong>Content Liability</strong></h3>

                <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

                <h3><strong>Your Privacy</strong></h3>

                <p>Please read Privacy Policy</p>

                <h3><strong>Reservation of Rights</strong></h3>

                <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

                <h3><strong>Removal of links from our website</strong></h3>

                <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

                <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

                <h3><strong>Disclaimer</strong></h3>

                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

                <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>

                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

                <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
            </div>
        </div>
    )
}


export default TermsScreen;