import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';


const range = (st, en) => {
    var list = [];
    for (var i = st; i < en; i++) {
        
        list.push(i);
    }
    return list;
}

const Paginator = props => {
    let { shop, show_per_page, strings, rep } = useContext(AppContext);
    let { count, back, to, next, can_move_back, can_move_forward, offset } = shop;
    const [before, set_before] = useState([]);
    const [after, set_after] = useState([]);

    useEffect(() => {
        const pg = offset === 0 ? 1 : (offset / show_per_page) + 1;
        const mx_pg = Math.ceil(count / show_per_page);
        let before = range(1, pg);
        if (before.length > 2) {
            before = before.slice(before.length -2);
        }

        let after = [];
        if (pg < mx_pg) {
            after = range(pg + 1, mx_pg + 1).slice(0,2);
        }

        set_after(after);
        set_before(before);
    }, [count, offset, show_per_page])

    const go_to = pg => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        to(pg)
    }

    const to_next = () => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        next();
    } 

    const to_back = () => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        back();
    }

    return (
        <div className="page_buttons">
            <div style={{cursor:'pointer'}} onClick={to_back}><FaChevronLeft size={20} color={can_move_back ? '#000' : '#ccc'} /></div>

            {before.map((x,i) => <span key={i} onClick={() => go_to(x)} className="before_pg">{x}</span>)}
            
            <div className="page_text">{strings['page']} {offset === 0 ? 1 : (offset / show_per_page) + 1}</div>
            
            {after.map((x,i) => <span onClick={() => go_to(x)} key={i} className="after_pg">{x}</span>)}

            <div style={{cursor:'pointer'}} onClick={to_next}> <FaChevronRight size={20} color={can_move_forward ? '#000' : '#ccc'} /> </div>
        </div>
    )
}

export default Paginator;