import React from 'react';

const AsCustomer = props => {
    const { rep } = props;

    if (rep.is_rep === false) {
        return null;
    }

    if (rep.as_customer === null) {
        return null;
    }

    return (
        <div className="as_customer">
            Shopping as {rep.customers[rep.as_customer].username}
        </div>
    )
}

export default AsCustomer;