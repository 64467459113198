import React from 'react';

const Titled = props => {
    return (
        <div style={{alignSelf:'stretch'}}>
            <h2 style={{marginLeft: 10}}>{props.title}</h2>
            {props.children}
        </div>
    )
}

export default Titled;