import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import { img_url } from '../config';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ProductSquares = props => {
    const eans = props.eans;
    const count = eans.length;
    let img_set;
    if (count === 1) {
        img_set = 'one';
    } else if (count <= 4) {
        img_set = 'four';
    } else if (count <= 9) {
        img_set = 'nine';
    } else if (count <= 16) {
        img_set = 'sixteen'
    }
    return (
        <div className={`image_set ${img_set}`}>
            {eans.map((e,i) => <img alt={i} src={img_url + '/' + e + '.jpg'} className="tile" />)}
        </div>
    )
}

const HeaderRow = () => {
    return (
        <div className="product">
            <div className="column header" style={{width: '10%'}}></div>
            <div className="column header" style={{width: '10%'}}>Product Code</div>
            <div className="column header" style={{width: '20%'}}>Description</div>
            <div className="column header" style={{width: '10%'}}>Size</div>
            <div className="column header" style={{width: '10%'}}>Case</div>
            <div className="column header" style={{width: '15%'}}>Ean</div>
            <div className="column header" style={{width: '10%'}}>Price</div>
            <div className="column header" style={{width: '10%'}}>Cases Ordered</div>
        </div>
    )
}

const ProductRow = props => {
    const { product } = props;
    const base_prod = product.product;
    return (
        <div className="product">
            <div className="column" style={{width: '10%'}}>
                <img alt={base_prod.description} src={img_url + '/' + base_prod.ean + '.jpg'} style={{width: 60, height: 60}} />
            </div>
            <div className="column" style={{width: '10%'}}>{base_prod.albcode}</div>
            <div className="column" style={{width: '20%', alignItems: 'flex-start'}}>{base_prod.description}</div>
            <div className="column" style={{width: '10%'}}>{base_prod.size}</div>
            <div className="column" style={{width: '10%'}}>{base_prod.case}</div>
            <div className="column" style={{width: '15%'}}>{base_prod.ean}</div>
            <div className="column" style={{width: '10%'}}>€{product.price.toFixed(2)}</div>
            <div className="column" style={{width: '10%'}}>{product.quantity}</div>            
        </div>
    )
}

const OrderComponent = props => {
    const [showing_products, set_showing_products] = useState(false);
    const { order } = props;
    const { add_order_lines, strings, language } = useContext(AppContext);

    const toggle_prods = () => {
        if (order.products === false) {
            add_order_lines(order.id);
        }
        set_showing_products(!showing_products);
    }

    if (order.lines === 0) {
        return null;
    }

    let product_message;
    if (order.lines === 1) {
        product_message = order.products_brief[0].description
    } else if (order.lines < 4) {
        product_message = order.products_brief.slice(0, order.products_brief.length -1).map(x => x.description).join(', ') + ' and ' + order.products_brief[order.products_brief.length-1].description
    } else {
        const leftover = order.lines - 3;
        product_message =  order.products_brief.slice(0, 3).map(x => x.description).join(', ') + ' and ' + leftover + ' more';
    }

    return (
        <div className={`order item${props.highlight === true ? ' highlight' : ''}`}>
            <div className="body">
                <ProductSquares eans={props.order.products_brief.slice(0, 16).map(x => x.ean)} />
                <div className="detail">
                    <div className="row">
                        <div className="column">
                            <span className="title">Order {order.id}</span>
                            <span className="datapoint">Placed: {moment(order.order_time).format("HH:mm, DD/MM/YYYY")}</span>
                            <span className="datapoint">{strings['products']}: {order.lines}</span>
                            <span className="datapoint">Total: {language === 'english' && '€'}{order.value.toFixed(2)}{language === 'portuguese' && '€'}</span>
                        </div>
                        <div className="column collapse" style={{justifyContent:'space-between'}}>
                            <div>
                                <span style={{fontSize: 16, display: 'block'}}>Products</span>
                            </div>
                            <div className="datapoint">{product_message}</div>
                            
                        </div>
                        <div className="column">
                            <span style={{fontSize: 16, display: 'block'}}>Status</span>
                            {order.status}
                        </div>
                    </div>
                </div>
                <span onClick={toggle_prods} className="view_all">{showing_products ? 'Hide' : 'View'} Products</span>
            </div>
            {showing_products && order.products !== false &&
                <div className="products">
                    <HeaderRow />
                    {order.products.map((x,i) => <ProductRow product={x} key={i} />)}
                </div>}
        </div>
    )
}

const OrderScreen = props => {
    const { orders, get_orders, orders_count, rep, strings } = useContext(AppContext);
    const [show_thanks, set_show_thanks] = useState(false);
    const [highlight_order, set_highlight_order] = useState(null);

    
    const get_params = () => {
        let url = new URL(window.location.href);
        let thanks = url.searchParams.get('thanks');
        let order_id = url.searchParams.get('order');
        set_show_thanks(thanks === '1');
        if (order_id) {
            set_highlight_order(parseInt(order_id));
        }

    }

    useEffect(() => {
        get_params();
    })

    return (
        <div style={{width: '100%'}}>
            <div className="container">
                {show_thanks && 
                    <div className="thanks">
                        Thanks for your order!
                    </div>
                }
                {orders.map((x,i) => <OrderComponent highlight={x.id === highlight_order} order={x} key={i} />)}
                {orders_count === 0 && <div className="signup">
                    {rep.as_customer !== null ? 'This customer hasn\'t placed any orders' : strings['you-havent-placed']}
                    <Link to='/shop' className="clear">
                        <div className="button" style={{marginTop: 10}}>{strings['browse-products']}</div>    
                    </Link>
                </div>}
                {orders.length > 0 &&
                    <div className="list_footer">
                        {orders.length < orders_count && <div onClick={get_orders} className="button fill">Load More</div>}
                    </div>
                }
                
            </div>
        </div>
    )
}

export default OrderScreen;