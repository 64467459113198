import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context';

const AboutScreen = props => {
    const { strings, logged_in } = useContext(AppContext);
    return (
        <div className="container">
            <div className="about_box">
                <h3>{strings['albany-is']}</h3>

                <h4>{strings['about-albany']}</h4>
                <p>{strings['alb-uk-registered']}</p>
                <h4>{strings['our-service']}</h4>
                <p>{strings['our-service-text']}</p><br></br>
                <div className="button_row">
                    <Link to='/contact' className="clear">
                        <div className="button">{strings['contact-us']}</div>
                    </Link>
                    {logged_in === false && <Link to='/create_account' className="clear">
                        <div className="button">{strings['apply-for-account']}</div>
                    </Link>}
                </div>
            </div>
        </div>
    )
}

export default AboutScreen;