import React, {createContext} from 'react';
import { Cookies } from 'react-cookie-consent';
import ProdModal from './components/edit_prod_modal';
import { data } from './dt';
import OOSModal from './components/oos_modal';
import { english, portuguese } from './strings';
export const AppContext = createContext();

let accept_cookies = Cookies.get('accept_cookie');
let lang = 'english';
if (accept_cookies === 'true') {
    accept_cookies = true;
    const cookie_lang = Cookies.get('language');
    if (cookie_lang === 'english' || cookie_lang === 'portuguese') {
        lang = cookie_lang
    }
} else if (accept_cookies === 'false') {
    accept_cookies = false;
} else {
    accept_cookies = null;
}

const blank_filters = {
    in_stock: false,
    faves_only: false,
    brands: false,
    category_ones: false,
    category_twos: false,
    promotions: false,
    sort_by: 'name',
    search_term: null,
    offset: 0,
    wigigs: 'range'
}

const plain_state = {
    strings: lang  === 'english' ? english : portuguese,
    language: lang,
    products: [],
    product_count: 0,
    token: null,
    user: null,
    basket: [],
    basket_counts: {},
    product_tick: 0,
    search_prods: [],
    search_count: 0,
    global_load: false,
    following: [],
    loading_following: false,
    products_in_view: {},
    prods_in_view_list: [],
    stocks_override: {},
    basket_tick: 0,
    orders: [],
    loading_orders: false,
    init_loaded:false,
    init_loading: false,
    orders_count: 0,
    
    offset: 0,
    product_sort: 'name',
    search_term: null,
    search_sort: 'name',
    search_offset: 0,
    did_search: 0,
    stock_only: false,
    faves_only: false,
    category_one: null,
    category_two: null,
    category_three: null,
    prom_id: null,
    filter_brand: null,

    promotions: [],
    loading_proms: false,

    customers: {},
    loading_customers: false,
    shopping_for_customer: null,

    loading_top_picks: false,
    top_picks: [],

    rep_baskets: {},
    rep_basket_counts: {},
    rep_orders: {},
    rep_orders_count: {},
    rep_pricing: {},
    rep_pricing_tick:0,
    rep_customer_stats: {},
    rep_faves: {},
    rep_follows: {},
    rep_tick: 0,
    tag_tick: 0,
    rep_requests: {},
    rep_reports_list: [],

    locality: 'english',

    show_admin_bar: false,
    force_admin_bar: false,

    is_rep: false,
    prod_edit_modal: null,
    category_map: [],

    OOSProduct: null,
    cookies_accepted: accept_cookies,

    loading_brands: false,
    loaded_brands: false,
    brands: [],
    show_per_page: 35,

    rep_tags: {},

    request_product: null,
    filters: blank_filters,
    filter_tick: 0,

    products_library: {},
    products_lib_tick: 0,
    product_ids: [],

    shop_view_type: 'grid'

}

class AlbanyPortugal extends React.Component {
    constructor(props) {
        super(props);
        this.state = plain_state
    }

    //TOP PICKS
    set_top_pick = (prod_id, top_pick) => {
        data.rep.set_top_pick(this.state.token, prod_id, top_pick, () => {
            if (prod_id in this.state.products_library) {
                let { products_library } = this.state;
                let product = products_library[prod_id];
                product.is_top_pick = top_pick;
                products_library[prod_id] = product;
                this.setState({products_library});            
            }
            if (this.state.prod_edit_modal) {
                if (this.state.prod_edit_modal.id === prod_id) {
                    let edit_prod = this.state.prod_edit_modal;
                    edit_prod.is_top_pick = top_pick;
                    this.setState({prod_edit_modal: edit_prod});
                }
            }
        })
    }

    //REPORTS
    get_reports = () => {
        data.rep.reports.get_list(this.state.token, (reports) => this.setState({rep_reports_list: reports}));
    }

    download_report = (key, cb, extraData={}) => {
        const rep = this.state.rep_reports_list.find(x => x.key === key).name;
        data.rep.reports.download_report(this.state.token, key, cb, rep, extraData);
    }

    //WIGIGS

    //REQUESTS

    add_request = (product_id, user_id, quantity) => {
        const request = data.rep.add_request(this.state.token, product_id, user_id, quantity);
        let rqs;
        if (user_id in this.state.rep_requests) {
            rqs = this.state.rep_requests[user_id];
        } else {
            rqs = [];
        }

        rqs.push(request);
        let rep_requests = this.state.rep_requests;
        rep_requests[user_id] = rqs;
        this.setState({rep_requests});
    }

    set_request_product = product => this.setState({request_product: product});

    get_user_requests = async (user_id) => {
        let requests = await data.rep.get_user_requests(this.state.token, user_id);
        requests = Object.assign({}, this.state.rep_requests, requests);
        this.setState({rep_requests: requests})
    }
    

    //PRODUCT INFO

    fill_out_products = async (ids) => {
        const products = await data.products.fill_out(this.state.token, ids);
        const library = Object.assign({}, this.state.products_library, products);
        this.setState({products_library: library, products_lib_tick: this.state.products_lib_tick + 1}, () => {
            if (this.state.is_rep === true) {
                let rep_tags = this.state.rep_tags;
                Object.values(products).forEach(e => {
                    rep_tags[e.id] = e.tags;
                })
                this.setState({rep_tags});
            }
        })
    }

    clear_filters_multi = (typ, cb=null) => {
        let { filters } = this.state;
        filters[typ] = false;
        this.setState({filters, offset:0, filter_tick: this.state.filter_tick + 1}, cb !== null ? cb : this.fetch_products);
    }

    add_to_filters_multi = (value, typ) => {
        let { filters } = this.state;
        if (filters[typ] === false) {
            filters[typ] = [value];

        } else if (filters[typ].indexOf(value) === -1) {
            filters[typ].push(value);

        }
        this.setState({filters, offset: 0, filter_tick: this.state.filter_tick + 1}, this.fetch_products);
    }

    remove_from_filters_multi = (value, typ) => {
        let { filters } = this.state;
        if (filters[typ] !== false) {
            if (filters[typ].indexOf(value) !== -1) {
                filters[typ] = filters[typ].filter(x => x !== value);
            }
            if (filters[typ].length === 0) {
                filters[typ] = false;
            }
            this.setState({filters, offset:0, filter_tick: this.state.filter_tick + 1}, this.fetch_products);
        }
    }

    multi_to_one_val = (value, typ) => {
        let { filters } = this.state;
        filters[typ] = [value];
        this.setState({filters, offset:0, filter_tick: this.state.filter_tick + 1}, this.fetch_products);
    }

    set_filters_value = (value, typ) => {
        let { filters } = this.state;

        if (typ === 'search_term') {
            filters.in_stock = false;
            filters.faves_only = false;
            filters.brands = false;
            filters.category_ones = false;
            filters.category_twos = false;
            filters.promotions = false;
            filters.search_term = value;

        } else {
            filters[typ] = value;
        }
        this.setState({filters, offset:0, filter_tick: this.state.filter_tick + 1}, this.fetch_products);
    }


    //BRANDS

    load_brands = () => {
        if (this.state.loaded_brands === false && this.state.loading_brands === false) {
            data.products.get_brands(brands =>  {
                if (brands) {
                this.setState({loaded_brands: true, loading_brands: false, brands: brands});
                }
            });
        }
    }

    //CATEGORIES

    get_cat_map = () => {
        data.products.get_cat_map(cats => this.setState({category_map: cats}))
    }

    //REP

    get_customer_stats = async (cust_id, force=false) => {
        if (cust_id in this.state.rep_customer_stats && force === false) {
            return this.state.rep_customer_stats[cust_id];
        } else {
            const stats = await data.rep.get_customer_stats(this.state.token, cust_id);
            let rep_customer_stats = this.state.rep_customer_stats;
            rep_customer_stats[cust_id] = stats;
            this.setState({rep_customer_stats});
            return stats; 
        }
    }


    //PRICING

    pricing_loop = () => {
        this.get_pricing_for_in_view()
        setTimeout(this.pricing_loop, 1500);
    }

    get_pricing_for_in_view = () => {
        if (this.state.shopping_for_customer !== null) {
            const to_get = this.state.prods_in_view_list;
            if (to_get.length > 0) {
                this.get_customer_pricing(to_get, this.state.shopping_for_customer);
            }
        }
    }


    //LOCALISATION

    switch_language = () => {
        const accept_cookie = Cookies.get('accept_cookie');
        if (this.state.language === 'english') {
            if (accept_cookie === 'true') {
                Cookies.set('language', 'portuguese')
            }
            this.setState({language: 'portuguese', strings: portuguese});
        } else {
            if (accept_cookie === 'true') {
                Cookies.set('language', 'english')
            }
            this.setState({language: 'english', strings: english});
        }
    }

    pricing_loop = () => {
        if (!document.hidden) {
            this.get_pricing_for_in_view()
        }
        setTimeout(this.pricing_loop, 1500);
    }

    rep_switch_customer = user_id => {
        if (user_id === this.state.user.id) {
            this.setState({shopping_for_customer: null});
        } else {
            let rp = this.state.rep_pricing;
            if (!(user_id in rp)) {
                rp[user_id] = {};
            }
            this.rep_get_user_basket(user_id, () => this.setState({shopping_for_customer: user_id, rep_pricing: rp}));
            this.rep_get_orders(user_id);
            this.get_customer_pricing(this.state.prods_in_view_list, user_id);
            this.get_customer_faves(user_id);
            this.get_customer_follows(user_id);
            this.get_user_requests(user_id)
        }
    }

    
    get_customer_faves = user_id => {
        data.rep.get_customer_faves(this.state.token, user_id, (faves) => {
            let rep_faves = this.state.rep_faves;
            rep_faves[user_id] = faves;
            this.setState({rep_faves});
        })
    }

    get_customer_follows = user_id => {
        data.rep.get_customer_follows(this.state.token, user_id, (follows) => {
            let rep_follows = this.state.rep_follows;
            rep_follows[user_id] = follows;
            this.setState({rep_follows});
        })
    }

    get_customer_pricing = (codes, user_id) => {
        if (!codes) {
            codes = this.state.product_ids.slice(0,this.state.show_per_page);
        }
        data.rep.get_pricing(this.state.token, user_id, codes, (pricing) => {
            let rep_pricing = this.state.rep_pricing;
            if (user_id in rep_pricing) {
                rep_pricing[user_id] = Object.assign({}, rep_pricing, pricing)
            } else {
                rep_pricing[user_id] = pricing;
            }

            this.setState({rep_pricing, rep_pricing_tick: this.state.rep_pricing_tick + 1});
        });
    }
    
    rep_get_user_basket = (user_id, callback) => {
        data.rep.get_user_basket(this.state.token, user_id, (basket) => {
            let rep_baskets = this.state.rep_baskets;
            rep_baskets[user_id] = basket;
            this.setState({rep_baskets}, () => this.rep_set_basket_counts(user_id, basket, callback));
        })
    }

    rep_add_to_basket = async (user_id, product, qty, callback=() => {}) => {
        await data.rep.add_to_basket(this.state.token, user_id, product.id, qty, ({override, new_prod}) => {
            let stocks_override = Object.assign({}, this.state.stocks_override, override);
            let rep_baskets = this.state.rep_baskets;
            let basket = JSON.parse(JSON.stringify(rep_baskets[user_id]));
            
            if (qty === 0 || new_prod === false) {
                basket = basket.filter(x => x.product.id !== product.id);
            } else {
                const i = basket.map(x => x.id).indexOf(new_prod.id);
                if (i === -1) {
                    basket.push(new_prod);
                } else {
                    basket.splice(i, 1, new_prod);
                }
            }
            rep_baskets[user_id] = basket;
            this.setState({stocks_override, rep_baskets}, () => {
                this.rep_set_basket_counts(user_id, basket, callback);
            })
        })
    }

    add_to_basket = async (product, qty, callback=() => {}) => {
        await data.basket.add(this.state.token, product.id, qty, ({override, new_prod}) => {
            let overrides = Object.assign({}, this.state.stocks_override, override);
            let basket = this.state.basket;
            
            if (qty === 0) {
                basket = basket.filter(x => x.product.id !== product.id);
            } else {
                const i = basket.map(x => x.id).indexOf(new_prod.id);
                if (i === -1) {
                    basket.push(new_prod);
                } else {
                    basket.splice(i, 1, new_prod);
                }
            }
            this.setState({stocks_override: overrides, basket}, () => {
                this.set_basket_counts(basket, callback);
            })
        });
    }

    rep_set_basket_counts = (user_id, basket, callback) => {
        let out = {};
        for (var i=0; i < basket.length; i++) {
            out[basket[i].product.id] = basket[i].quantity;
        }
        let rep_basket_counts = this.state.rep_basket_counts;
        rep_basket_counts[user_id] = out;

        this.setState({rep_basket_counts}, callback);
    }

    load_customers = () => {
        data.user.load_customers(this.state.token, customers => this.setState({customers, loading_customers: false}));
    }

    load_top_picks = () => {
        data.products.get_top_picks(this.state.token, (prods) => this.setState({top_picks: prods, loading_top_picks: false}, () => this.fill_out_products(prods)))
    }

    set_offset = (offset) => {
        if (this.state.search_term === null) {
            this.setState({offset});
        } else {
            this.setState({search_offset: offset});
        }
    }

    set_search_offset = o => {
        this.setState({search_offset: o});
    }

    next_pg = () => {
        let { filters, offset, show_per_page, product_count } = this.state;
        const new_offset = offset + show_per_page;
        const current_count = product_count;
        if (new_offset < current_count) {
            offset = new_offset;
            this.setState({filters, offset, filter_tick: this.state.filter_tick + 1}, () => {
                if (this.state.product_ids.length <= (new_offset + this.state.show_per_page)) {
                    this.fetch_products(new_offset);
                }
            });
        }
    }

    to_pg = (pg) => {
        let { filters, offset } = this.state;
        const new_offset = (pg-1) * this.state.show_per_page;
        if (new_offset > offset) {
            const limit = new_offset - offset;
            this.fetch_products(offset+this.state.show_per_page, limit, true);
        }
        this.setState({filters, offset: new_offset, filter_tick: this.state.filter_tick + 1});
    }

    back_pg = () => {
        let { filters, offset } = this.state;
        if (offset > 0) {
            offset = offset - this.state.show_per_page;
            this.setState({filters, offset, filter_tick: this.state.filter_tick + 1})
        }
    }

    get_orders = async (callback=() => {}) => {
        const { orders, count } = await data.orders.get(this.state.token, this.state.orders.length);
        this.setState({orders: this.state.orders.concat(orders), loading_orders: false, orders_count: count});
    }

    rep_get_orders = async (user_id, callback=() => {}) => {

        let rep_orders = this.state.rep_orders;
        let rep_orders_count = this.state.rep_orders_count
        let offset;
        if (user_id in rep_orders) {
            offset = rep_orders[user_id];
        } else {
            offset = 0;
        }
        const { orders, count } = await data.rep.get_orders(this.state.token, user_id, offset);

        let nords;
        if (user_id in rep_orders) {
            nords = rep_orders[user_id].concat(orders);
            rep_orders[user_id] = nords;
        } else {
            rep_orders[user_id] = orders;
        }

        rep_orders_count[user_id] = count;

        this.setState({rep_orders}, callback);
    }

    add_order_lines = async (order_id) => {
        const order_ids = this.state.orders.map(x => x.id);
        const ind = order_ids.indexOf(order_id);
        const order = await data.orders.get_lines(this.state.token, order_id);
        let orders = this.state.orders;
        orders.splice(ind, 1 ,order);
        this.setState({orders});
    }

    add_order_lines_rep = async (order_id, target_id) => {
        let customer_orders = this.state.rep_orders[target_id];
        const ind = customer_orders.map(x => x.id).indexOf(order_id);
        const order = await data.orders.get_lines(this.state.token, order_id);
        customer_orders.splice(ind, 1, order);
        let rep_orders = this.state.rep_orders;
        rep_orders[target_id] = customer_orders;
        this.setState({rep_orders})
    }

    rep_place_order = async(user_id, callback=() => {}) => {
        const { success, order, amendments } = await data.rep.place_order(this.state.token, user_id);
        if (success === true) {
            let rep_orders = this.state.rep_orders;
            let rep_baskets = this.state.rep_baskets;
            let rep_basket_counts = this.state.rep_basket_counts;
            let orders;

            if (user_id in rep_orders) {
                orders = rep_orders[user_id];
                orders.unshift(order);
            } else {
                rep_orders[user_id] = [order];
            }

            rep_baskets[user_id] = [];
            rep_basket_counts[user_id] = {}
            this.setState({rep_orders, rep_basket_counts, rep_baskets});
            callback(true, order.id, amendments);
        } else {
            callback(false);
        }
    }

    place_order = async (callback=() => {}) => {
        const { success, order, amendments } = await data.orders.place(this.state.token);
        if (success === true) {
            let orders = this.state.orders;
            orders.unshift(order);
            this.setState({basket: [], basket_counts: {}, orders});
            callback(true, order.id, amendments);
        } else {
            callback(false);
        }
    }

    get_price_override = (customer_id, callback=() => {}) => {
        data.rep.get_override(this.state.token, customer_id, override => callback(override));
    } 

    set_price_override = (override, customer_id, callback=() => {}) => {
        data.rep.set_override(this.state.token, customer_id, override, () => {
            let rep_pricing = this.state.rep_pricing;
            rep_pricing[customer_id] = {};
            this.setState({rep_pricing});
        });
    }

    logout = () => {
        this.setState(plain_state, this.load_assets_outside);
        Cookies.remove('token');
    }

    stock_cycle = () => {
        if (!document.hidden) {
            this.update_product_stocks();
        }
        setTimeout(this.stock_cycle, 20000);
    }

    update_product_stocks = async () => {
        const codes = this.state.prods_in_view_list;
        const stocks = await data.products.get_stock(this.state.token, codes);
        const updated_override = Object.assign({}, this.state.stocks_override, stocks);
        this.setState({stocks_override: updated_override});
    }

    get_visible_products = () => {
        const in_view = Object.keys(this.state.products_in_view).filter(key => this.state.products_in_view[key] === true);
        this.setState({prods_in_view_list: in_view});
    }

    set_product_in_view = (product_code, in_view) => {
        let piv = this.state.products_in_view;
        piv[product_code] = in_view;
        this.setState({products_in_view: piv}, this.get_visible_products);
    }

    fetch_products = async(offset=0, limit=null, do_not_refresh=false) => {
        let { filters } = this.state;

        if (limit === null) {
            limit = this.state.show_per_page;
        }

        this.setState({global_load: true}, async () => {
            const { products, count } = await data.products.fetch(this.state.token, offset, limit, filters);
            let current_products;

            if (offset > 0 || do_not_refresh === true) {
                current_products = this.state.product_ids.concat(products);
            } else {
                current_products = products;
            }

            this.setState({
                product_ids: current_products,
                products_loading: false,
                product_count: count,
                product_tick: this.state.product_tick + 1,
                global_load: false,
            }, () => {
                this.get_missing_products(products);
            })
        })
    }

    get_missing_products = ids => {
        ids = ids.filter(x => !(x in this.state.products_library));
        this.fill_out_products(ids);
    }

    load_promotions = () => {
        data.products.get_promotions(this.state.token, (proms) => {
            this.setState({promotions: proms, loading_proms: false})
        });
    }
  
    toggle_fave_product = product => {
        product.favourite = !product.favourite;
        let { products_library } = this.state;
        products_library[product.id] = product;
        this.setState({products_library, products_lib_tick: this.state.products_lib_tick + 1}, () => {
            data.products.set_fave(this.state.token, product.id, product.favourite);
        });
    }

    rep_toggle_fave = (user_id, product_id, should_fave) => {
        let rep_faves = this.state.rep_faves;
        let customer_faves = rep_faves[user_id];
        if (!customer_faves) {
            customer_faves = [];
        }

        if (should_fave === true) {
            customer_faves.push(product_id);
        } else if (should_fave === false){ 
            customer_faves = customer_faves.filter(x => x !== product_id);
        }

        rep_faves[user_id] = customer_faves;
        this.setState({rep_faves, rep_tick: this.state.rep_tick + 1}, () => {
            data.rep.set_cust_fave(this.state.token, user_id, product_id, should_fave);
        })
    }

    rep_toggle_follow = (user_id, product_id, should_follow) => {
        let rep_follows = this.state.rep_follows;
        let customer_follows = rep_follows[user_id];
        if (!customer_follows) {
            customer_follows = [];
        }

        if (should_follow === true && customer_follows.indexOf(product_id) === -1) {
            customer_follows.push(product_id);
        } else if (should_follow === false) {
            customer_follows = customer_follows.filter(x => x !== product_id);
        }

        rep_follows[user_id] = customer_follows;
        this.setState({rep_follows, rep_tick: this.state.rep_tick + 1}, () => {
            data.rep.set_cust_follow(this.state.token, user_id, product_id, should_follow);
        })
    }

    set_basket_counts = (basket, callback=() => {}) => {
        let out = {};
        for (var i=0; i < basket.length; i++) {
            out[basket[i].product.id] = basket[i].quantity;
        }
        this.setState({basket_counts: out}, callback);
    }

    componentDidUpdate(prevprops, prevstate) {
        if (this.state.token && !prevstate.token) {
            this.load_assets_inside();
        }
    }

    reset_catalogue_assets = () => {
        this.setState({filters: blank_filters, product_ids: []}, this.fetch_products);
        this.load_top_picks();
    }

    load_assets_inside = () => {
        this.pricing_loop();
        this.load_brands();
        this.load_promotions();
        if (this.state.init_loading === false) {
            this.setState({init_loading: true}, () => {
                this.token_login(() => {
                    if (!this.state.basket_loading) {
                        this.setState({basket_loading: true}, this.get_basket);
                    }
                    if (!this.state.products_loading) {
                        this.setState({products_loading: true, products_library: {}}, this.fetch_products);
                    }
                    if (!this.state.loading_following) {
                        this.setState({loading_following: true}, this.get_following);
                    }
                    if (!this.state.loading_orders) {
                        this.setState({loading_orders: true}, this.get_orders)
                    }
                    if (this.state.is_rep && this.state.loading_customers === false) {
                        this.setState({loading_customers: true}, this.load_customers)
                    }
                    if (!this.state.loading_top_picks) {
                        this.setState({loading_top_picks: true}, this.load_top_picks)
                    }
                });
            })
        }
    }

    load_assets_outside = () => {
        this.load_brands();
        if (!this.state.products_loading) {
            this.setState({products_loading: true}, this.fetch_products);
        }
        if (!this.state.loading_proms) {
            this.setState({loading_proms: true}, this.load_promotions)
        }

        this.load_top_picks();
    }

    inside_outside_load = async () => {
        const token = Cookies.get('token');

        if (token) {
            const token_ok = await data.token.is_ok(token);
            if (token_ok === true) {
                this.setState({token});
            }
        } else {
            this.load_assets_outside();
        }
    }

    get_following = () => {
        data.products.following(this.state.token, (following) => this.setState({following, loading_following: false}));
    }

    follow = (product_id, should_follow) => {
        let following = this.state.following;
        if (should_follow === true) {
            following.push(product_id);
        } else {
            following = following.filter(x => x !== product_id);
        }
        this.setState({following: following, rep_tick: this.state.rep_tick + 1}, () => data.products.follow(this.state.token, should_follow, product_id));
    }

    componentDidMount() {
        this.resize_window();
        this.inside_outside_load();
        this.stock_cycle();
        this.get_cat_map();
        window.addEventListener('resize', this.resize_window);
    }

    resize_window = e => {
        if (window.innerWidth >= 1600 && this.state.show_admin_bar === false) {
            this.setState({show_admin_bar: true, force_admin_bar: false});
        } else if (window.innerWidth < 1600 && this.state.show_admin_bar === true) {
            this.setState({show_admin_bar: false})
        }
    }

    force_admin = _ => this.setState({force_admin_bar: _})

    get_token = async (email, password) => {
        const user = await data.token.get(email, password, this.state.cookies_accepted);
        this.setState({user: user, token: user.token, is_rep: user.account_types.indexOf('REP') !== -1});
    }

    token_login = async(cb) => {
        if (this.state.user !== null) {
            this.setState({is_rep: this.state.user.account_types.indexOf('REP') !== -1}, cb);
        } else {
            data.user.get_user(this.state.token, (user) => {
                this.setState({user: user, is_rep: user.account_types.indexOf('REP') !== -1}, cb)
            });
        }
    }

    token_to_state = token => this.setState({token})

    get_basket = async () => {
        const basket = await data.basket.get(this.state.token);
        this.setState({basket: basket, basket_loading: false}, () => this.set_basket_counts(basket));
    }

    add_to_basket = async (product, qty, callback=() => {}) => {
        await data.basket.add(this.state.token, product.id, qty, ({override, new_prod}) => {
            let overrides = Object.assign({}, this.state.stocks_override, override);
            let basket = this.state.basket;
            
            if (qty === 0) {
                basket = basket.filter(x => x.product.id !== product.id);
            } else {
                const i = basket.map(x => x.id).indexOf(new_prod.id);
                if (i === -1) {
                    basket.push(new_prod);
                } else {
                    basket.splice(i, 1, new_prod);
                }
            }
            this.setState({stocks_override: overrides, basket}, () => {
                this.set_basket_counts(basket, callback);
            })
        });
    }

    shop = () => {
        let scoped;
        const { offset, filters } = this.state;

        scoped = {
            in_search: filters.search_term !== null,
            offset: offset,
            count: this.state.product_count,
            can_move_forward: offset + this.state.show_per_page < this.state.product_count,
            can_move_back: offset > 0,
            viewing: this.state.product_ids.slice(offset, offset + this.state.show_per_page)
        }

        let current_cat = null;
        if (this.state.category_one !== null || this.state.category_two !== null) {
            current_cat = (this.state.category_one === null ? 'All' : this.state.category_one) + '/' + (this.state.category_two === null ? 'All' : this.state.category_two);
        }

        scoped.product_filters = {
            filters: this.state.filters,
            set_sort: val => this.set_filters_value(val, 'sort_by'),
            set_in_stock: val => this.set_filters_value(val, 'in_stock'),
            set_faves_only: val => this.set_filters_value(val, 'faves_only'),
            add_brand: val => this.add_to_filters_multi(val, 'brands'),
            remove_brand: val => this.remove_from_filters_multi(val, 'brands'),
            add_category_one: val => this.add_to_filters_multi(val, 'category_ones'),
            remove_category_one: val => this.remove_from_filters_multi(val, 'category_ones'),
            add_category_two: val => this.add_to_filters_multi(val, 'category_twos'),
            remove_category_two: val => this.remove_from_filters_multi(val, 'category_twos'),
            add_promotion: val => this.add_to_filters_multi(val, 'promotions'),
            remove_promotion: val => this.remove_from_filters_multi(val, 'promotions'),
            search_term: this.state.filters.search_term,
            search: val => this.set_filters_value(val, 'search_term'),
            clear_search: val => this.set_filters_value(null, 'search_term'),
            clear_multi: (typ, cb=null) => this.clear_filters_multi(typ, cb),
            sort_by: this.state.filters.sort_by,
            filter_tick: this.state.filter_tick,
            multi_to_one_val: this.multi_to_one_val,
            clear_all: () => this.setState({
                filters: {
                    in_stock: false,
                    brands: false,
                    promotions: false,
                    faves_only: false,
                    category_ones: false,
                    category_twos: false,
                    sort_by: 'name',
                    search_term: null,
                }, offset: 0, filter_tick: this.state.filter_tick + 1}, this.fetch_products)
        }

        scoped.filters = {
            in_stock: this.state.stock_only,
            faves_only: this.state.faves_only,
            sort_by: this.state.product_sort,
            set_in_stock: this.toggle_in_stock,
            set_faves_only: this.toggle_fave_only,
            set_sort: this.resort_products,
            brand: this.toggle_brand_filter,
            current_brand: this.state.filter_brand,
            current_cat: current_cat
        }

        scoped.next = this.next_pg;
        scoped.back = this.back_pg;
        scoped.to = this.to_pg;
        scoped.clear_search = val => this.set_filters_value(null, 'search_term');
        scoped.set_term = val => this.set_filters_value(val, 'search_term');
        scoped.search_term = this.state.filters.search_term;
        return scoped;
    }

    basket = () => {
        if (this.state.is_rep === true && this.state.shopping_for_customer !== null) {
            const as_user = this.state.shopping_for_customer;
            return {
                basket: this.state.rep_baskets[as_user],
                basket_counts: this.state.rep_basket_counts[as_user],
                add_to_basket: (product, qty, callback) => this.rep_add_to_basket(this.state.shopping_for_customer, product, qty, callback)
            }
        } else {
            return {
                basket: this.state.basket,
                basket_counts: this.state.basket_counts,
                add_to_basket: this.add_to_basket
            }
        }
    }

    order = () => {
        if (this.state.is_rep === true && this.state.shopping_for_customer !== null) {
            const as_user = this.state.shopping_for_customer;
            return {
                orders: this.state.rep_orders[as_user] || [],
                get_orders:(callback) => this.rep_get_orders(as_user, callback),
                place_order: (callback) => this.rep_place_order(as_user, callback),
                orders_count: this.state.rep_orders_count[as_user],
                add_order_lines: (order_id) => this.add_order_lines_rep(order_id, as_user)
            }
        } else {
            return {
                orders: this.state.orders,
                get_orders: this.get_orders,
                place_order: this.place_order,
                orders_count: this.state.orders_count,
                add_order_lines: this.add_order_lines
            }
        }
    }

    replace_product_in_place = (prod, callback=() => {}) => {
        let { products_library } = this.state;
        products_library[prod.id] = prod;
        this.setState({products_library, products_lib_tick: this.state.products_lib_tick + 1});
        this.reset_rep_pricing();
    }

    update_product = (prod_id, description, size, base_price, category, callback=() => {}) => {
        data.rep.update_product(this.state.token, prod_id, description, size, base_price, category, (product) => {
            callback();
                this.replace_product_in_place(product)
        });
    }

    reset_rep_pricing = () => {
        let out = {}
        const custs = Object.keys(this.state.rep_pricing);
        for (var i=0; i < custs.length; i++) {
            out[custs[i]] = {};
        }
        this.setState({rep_pricing: out});
    }

    download_catalogue = () => {
        data.rep.download_catalogue(this.state.token);
    }

    download_bs = () => {
        data.rep.download_bs(this.state.token);
    }

    hard_catalogue_reset = () => {
        this.reset_rep_pricing();
        this.setState({
            products: [],
            offset: 0,
            search_offset: 0,
            search_term: null,
            search_prods: [],
            product_count: 0,
            search_count: 0,
            stocks_override: {},
            category_map: []
        }, () => {
            this.reset_catalogue_assets();
            this.get_cat_map()
        });
    }

    rep_customer_follows = () => {
        if (this.state.shopping_for_customer !== null) {
            const follows = this.state.rep_follows;
            if (this.state.shopping_for_customer in follows) {
                return follows[this.state.shopping_for_customer];
            } else {
                return []
            }
        } else {
            return null;
        }
    }

    rep_customer_faves = () => {
        if (this.state.shopping_for_customer !== null) {
            const faves = this.state.rep_faves;
            if (this.state.shopping_for_customer in faves) {
                return faves[this.state.shopping_for_customer];
            } else {
                return [];
            }
        } else {
            return null;
        }
    }

    add_product_tag = (product_id, tag) => {
        data.rep.add_product_tag(this.state.token, product_id, tag);
        if (product_id in this.state.rep_tags) {
            let rtags = this.state.rep_tags;
            let ptags = rtags[product_id];
            if (ptags.indexOf(tag) === -1) {
                ptags.push(tag);
            }
            rtags[product_id] = ptags;
            this.setState({rep_tags: rtags});
        }
    }

    remove_product_tag = (product_id, tag, callback) => {
        data.rep.remove_product_tag(this.state.token, product_id, tag);
        if (product_id in this.state.rep_tags) {
            let rtags = this.state.rep_tags;
            let ptags = rtags[product_id];
            ptags = ptags.filter(x => x !== tag);
            rtags[product_id] = ptags;
            this.setState({rep_tags: rtags}, callback);
        }
    }

    edit_next_prod = (step=1, cb) => {
        if (this.state.is_rep !== true) {
            return;
        }

        if (this.state.prod_edit_modal === null) {
            this.setState({prod_edit_modal: this.state.products[0]});
        } else {
            let ind;
            if (this.state.prod_edit_modal !== null) {
                ind = this.state.product_ids.indexOf(this.state.prod_edit_modal.id);
                if (ind !== -1) {
                    if (step === 1) {
                        if (ind === (this.state.product_ids.length -1)) {
                            this.setState({prod_edit_modal: this.state.products_library[this.state.product_ids[0]]}, cb);
                        } else {
                            this.setState({prod_edit_modal: this.state.products_library[this.state.product_ids[ind+1]]}, cb)
                        }
                    } else if (step === -1) {
                        if (ind === 0) {
                            this.setState({prod_edit_modal: this.state.products_lib_tick[this.state.product_ids.length -1]}, cb);
                        } else {
                            this.setState({prod_edit_modal: this.state.products_library[this.state.product_ids[ind-1]]}, cb);
                        }
                    }
                }
            }
        }
    }

    brands_list = () => {
        return this.state.brands;
        
    }

    build_context = () => {
        return Object.assign({
            shop_view_type: this.state.shop_view_type,
            set_shop_view_type: v => this.setState({shop_view_type: v}),
            show_per_page: this.state.show_per_page,
            user: this.state.user,
            locality: this.state.locality,
            top_picks: this.state.top_picks,
            category_map: this.state.category_map,
            logged_in: this.state.token !== null,
            products: this.state.products,
            basket_tick: this.state.basket_tick,
            get_products: this.get_products,
            product_count: this.state.product_count,
            global_load: this.state.global_load,
            toggle_fave_product: this.toggle_fave_product,
            following: this.state.following,
            follow: this.follow,
            set_product_in_view: this.set_product_in_view,
            stocks_override: this.state.stocks_override,
            token_to_state: this.token_to_state,
            logout: this.logout,
            toggle_fave_only: this.toggle_fave_only,
            faves_only: this.state.faves_only,
            add_order_lines: this.add_order_lines,
            shop: this.shop(),
            current_prom_id: this.state.prom_id,
            promotions: this.state.promotions,
            show_admin_bar: this.state.show_admin_bar,
            force_admin_bar: this.state.force_admin_bar,
            force_admin: this.force_admin,
            cookies_accepted: this.state.cookies_accepted,
            accept_cookies: this.accept_cookies,
            set_oos_product: (product) => this.setState({OOSProduct: product}),
            strings: this.state.strings,
            language: this.state.language,
            switch_language: this.switch_language,
            token: this.state.token,
            brands: this.brands_list(), 
            products_library: this.state.products_library,
            products_lib_tick: this.state.products_lib_tick,
            rep: {
                set_top_pick: this.set_top_pick,
                reports: {
                    reports: this.state.rep_reports_list,
                    download_report: this.download_report
                },
                is_rep: this.state.is_rep,
                customers: this.state.customers,
                as_customer: this.state.shopping_for_customer,
                shop_as_customer: this.rep_switch_customer,
                get_user_basket: this.rep_get_user_basket,
                add_to_basket: this.rep_add_to_basket,
                pricing: this.state.rep_pricing,
                pricing_tick: this.state.rep_pricing_tick,
                get_override: this.get_price_override,
                set_override: this.set_price_override,
                prod_edit_modal: this.state.prod_edit_modal,
                show_modal_prod: product => this.setState({prod_edit_modal: product}),
                update_product: this.update_product,
                download_catalogue: this.download_catalogue,
                download_bs: this.download_bs,
                upload_catalogue: (file, cb) => data.rep.upload_catalogue(this.state.token, file, cb),
                hard_catalogue_reset: this.hard_catalogue_reset,
                get_customer_stats: this.get_customer_stats,
                toggle_fave: (product_id, should_fave) => this.rep_toggle_fave(this.state.shopping_for_customer, product_id, should_fave),
                toggle_follow: (product_id, should_follow) => this.rep_toggle_follow(this.state.shopping_for_customer, product_id, should_follow),
                follows: this.rep_customer_follows(),
                faves: this.rep_customer_faves(),
                tick: this.state.rep_tick,
                add_product_tag: this.add_product_tag,
                remove_product_tag: this.remove_product_tag,
                tags: this.state.rep_tags,
                edit_next_prod: this.edit_next_prod,
                product_requests: this.state.rep_requests,
                request_product: {
                    set: this.set_request_product,
                    get: this.state.request_product,
                    send: this.add_request
                },
            }
        }, this.basket(), this.order())
    }

    render() {
        return (
            <AppContext.Provider value={this.build_context()}>
                {this.props.children}
                {this.state.prod_edit_modal && <ProdModal />}
                <OOSModal product={this.state.OOSProduct} close={() => this.setState({OOSProduct: null})} />
            </AppContext.Provider>
        )
    }
}

export default AlbanyPortugal;