import React from 'react';
import './app.scss';
import 'rc-footer/assets/index.css';
import Prov from './context';
import Router from './screens/router';

class App extends React.Component {
  render() {
    return (
        <Prov>
          <Router />
        </Prov>
    );
  }
}

export default App;
