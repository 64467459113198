export const english = {
    'home': 'Home',
    'shop': 'Shop',
    'create-account': 'Create an account',
    'contact-us': 'Contact Us',
    'contact': 'Contact',
    'product-search': 'Product search',
    'search': 'Search',
    'sort-by': 'Sort by',
    'best-sellers': 'Best Sellers',
    'category': 'Category',
    'login-create-pricing': 'Login or create an account to see pricing',
    'forgot-password': 'Forgot my password',
    'view-all': 'View All',
    'all-drinks': 'All Drinks',
    'cold-drinks': 'Cold Drinks',
    'hot-drinks': 'Hot Drinks',
    'all-food': 'All Food',
    'cereal': 'Cereal',
    'confectionary': 'Confectionary',
    'packets': 'Packets',
    'sauce-condiments': 'Sauce & Condiments',
    'snacks': 'Snacks',
    'tinned': 'Tinned',
    'all-non-food': 'All Non-Food',
    'health-and-beauty': 'Health & Beaty',
    'household': 'Household',
    'laundry': 'Laundry',
    'pet': 'Pet',
    'in-stock-only': 'In stock only',
    'page': 'Page',
    'apply-for-account': 'Apply for an account',
    'already-have-account': 'Already have an account',
    'click-here': 'Click here',
    'email-address': 'Email address',
    'your-name': 'Your Name',
    'business-name': 'Business Name',
    'phone-number': 'Phone Number',
    'website': 'Website',
    'address': 'Address',
    'submit': 'Submit',
    'have-question': 'Have a question?',
    'please-fill-form-send-message': 'Please fill out the form below to send us a message',
    'about-us': 'About Us',
    'albany-portugal-is-a': 'Albany Portugal is a supplier of leading branded grocery products',
    'our-brands': 'Our Brands',
    'case-size': 'Case Size',
    'in-basket': 'in basket',
    'promotions': 'Promotions',
    'favourites-only': 'Favourites Only',
    'add-to-faves': 'Add to favourites',
    'cancel-notification': 'Cancel notification',
    'add': 'Add +',
    'in-stock': 'in stock',
    'notify-me': 'Notify me when available',
    'showing': 'Showing',
    'due': 'Due',
    'out-of-stock': 'Out of stock',
    'orders': 'Orders',
    'show-orders': 'Show orders',
    'view-products': 'View products',
    'status': 'status',
    'placed': 'Placed',
    'admin': 'Admin',
    'select-customer': 'Select Customer',
    'drag-drop-cat': 'Drop catalogue here',
    'close': 'Close',
    'log-out': 'Log out',
    'special-offers': 'Special Offers',
    'prev': 'Prev',
    'next': 'Next',
    'order': 'Order',
    'shop-now': 'Shop Now',
    'intro-offer': 'Introductory Offer',
    'now-only': 'Now Only',
    'normal-price': 'Normal Price',
    'offer-ends-in': 'Offer ends in',
    'unstickered-price': 'Unstickered price',
    'customer': 'Customer',
    'thanks': 'Thank you',
    'password': 'Password',
    'account': 'Account',
    'albany-is': 'Albany Portugal is a supplier of leading branded grocery products',
    'about-albany': 'About Albany',
    'alb-uk-registered': "The Albany Group is made up of three key trading organisations. Albany Products Ltd UK has been supplying major branded goods, own label and exclusive products worldwide for over 20 years. Albany Ireland was established in 2012 under the same private ownership as Albany UK. Albany Portugal, a subsidiary of Albany UK, was established in 2015 and operates direct supplies to retailers and wholesale distributors throughout Portugal. With its links to Albany Ireland we are ideally positioned to source and offer products EU-EU without the delays caused by new UK-EU cross border checks. With a reputation for excellence in service and reliability, Albany strive to make all key products available at competitive prices across the group operations.",
    'our-service': 'Our Service',
    'our-service-text': 'Albany Portugal can supply all your UK and Irish branded product requirements. Goods ordered on our website are sent directly from Ireland and can be delivered to any address within Portugal.',
    'login': 'Login',
    'you-havent-placed': 'You haven\'t placed any orders yet',
    'browse-products': 'Browse Products',
    'clear': 'Clear',
    'top-picks': 'Top picks',
    'learn-more': 'Learn More',
    'remove-favourites': 'Remove from favourites',
    'more-products': 'More products',
    'unit': 'unit',
    'download-cat': 'Download current catalogue',
    'invalid-file': 'Invalid file, please try again',
    'price-override': 'Price Override',
    'sorry': 'Sorry',
    'this-product-is-out': 'This product is out of stock',
    'we-can-let-you': 'We can let you know when we secure more stock',
    'youve-reset': 'You\'ve reset your password, You can now login below',
    'message': 'Message',
    'place-order': 'Place Order',
    'reset-password': 'Reset password',
    'you-will-receive': 'You will receive an email with instructions to reset your password',
    'forgotten-your-password': 'Forgotten your Password',
    'base-price': 'Base price',
    'name': 'Name',
    'size': 'Size',
    'accept': 'Accept',
    'decline': 'Decline',
    'cookies': 'This website uses cookies to enhance the user experience.',
    'required': 'Required',
    'invalid-email': 'Invalid email',
    'sorry-now-una': 'Sorry, this product is now unavailable',
    'products': 'Products',
    'cases': 'Cases',
    'Total': 'Total',
    'youve-reset-your': 'You\ve reset your password, you can now login below',
    'or': 'or',
    'to-view-pricing': 'to view pricing',
    'your-details-have': 'Your details have been submitted and someone we\'ll be in touch soon to complete your application',
    'your-query-submitted': 'Your query has been submitted and we\'ll be in touch soon',
    'success-reset': 'You\'ve successfully reset your password. You can now log in below.',
    'promotions-only': 'Promotions only',
    'terms-conditions': 'Terms & Conditions',
    'priv-pol': 'Privacy Policy',
    'settings': 'Settings'
}

export const portuguese = {
    'terms-conditions': 'Termos & Condições',
    'priv-pol': 'Política de Privacidade',
    'settings': 'Definições',
    'success-reset': 'A sua password foi renovada com sucesso. Faça log in novamente',
    'your-query-submitted': 'NEED TRANSLATION',
    'your-details-have': 'NEED TRANSLATION',
    'to-view-pricing': '',
    'or': 'ou',
    'youve-reset-your': 'A sua password foi renovada com sucesso. Faça log in novamente',
    'total': 'Total',
    'cases': 'Caixas',
    'products': 'Produtos',
    'sorry-now-una': 'Lamentamos, este produto está indisponível',
    'invalid-email': 'Endereço de email inválido',
    'required': 'Obrigatório',
    'accept': 'Aceitar',
    'decline': 'Rejeitar',
    'cookies': 'Este website utiliza cookies para melhorar a experiência do utilizador.',
    'category': 'Categoria',
    'name': 'Nome',
    'size': 'Tamanho',
    'base-price': 'Preço base',
    'forgotten-your-password': 'Esqueceu-se da password?',
    'you-will-receive': 'Irá receber um email com as intruções para restaurar a sua password',
    'reset-password': 'Restaurar password',
    'place-order': 'Fazer encomenda',
    'message': 'Mensagem',
    'youve-reset': 'A sua password foi renovada com sucesso. Faça log in novamente',
    'this-product-is-out': 'Produto fora de stock',
    'we-can-let-you': 'Avisaremos disponibilidade de stock assim que possível',
    'sorry': 'Lamentamos',
    'price-override': 'Preço sobreposto',
    'invalid-file': 'Ficheiro inválido, por favor tente de novo',
    'download-cat': 'Descarregar catálogo',
    'unit': 'unidade',
    'more-products': 'Mais produtos',
    'remove-favourites': 'Remover dos favoritos',
    'learn-more': 'Saiba mais',
    'top-picks': 'Mais procurados',
    'clear': 'Limpar',
    'browse-products': 'Pesquisa de produtos',
    'you-havent-placed': 'Ainda não foram colocadas encomendas',
    'login': 'Entrar',
    'password': 'Palavra-passe',
    'contact': 'Contacto',
    'thanks': 'obrigado',
    'home': 'Início',
    'shop': 'Comprar',
    'account': 'Conta',
    'create-account': 'Criar uma conta',
    'contact-us': 'Contacte-nos',
    'product-search': 'Pesquisar produto',
    'search': 'Pesquisar',
    'sort-by': 'Escolher por',
    'best-sellers': 'Mais procurados',
    'login-create-pricing': 'Entrar ou criar conta de acesso',
    'forgot-password': 'Esqueci-me da palavra-passe',
    'view-all': 'Ver Todas',
    'all-drinks': 'Todas as Bebidas',
    'cold-drinks': 'Refrigerantes',
    'hot-drinks': 'Bebidas quentes (café, chá, etc)',
    'all-food': 'Comida',
    'cereal': 'Cereais e Variados',
    'confectionary': 'Chocolates, doces variados, pastelaria',
    'packets': 'Empacotados (Arroz,Massas,etc)',
    'sauce-condiments': 'Molhos e especiarias',
    'snacks': 'Biscoitos variados',
    'tinned': 'Enlatados',
    'all-non-food': 'Para casa e animais',
    'health-and-beauty': 'Saúde e Beleza',
    'household': 'Para a casa',
    'laundry': 'Lavandaria',
    'pet': 'Comida animal',
    'in-stock-only': 'Stock físico apenas',
    'page': 'Página',
    'apply-for-account': 'Crie a sua conta aqui',
    'already-have-account': 'Ja tem uma conta',
    'click-here': 'Clique aqui',
    'email-address': 'Endereço de e-mail',
    'your-name': 'O seu Nome',
    'business-name': 'Nome da Empresa',
    'phone-number': 'Contacto telefónico',
    'website': 'Website',
    'address': 'Morada',
    'submit': 'Submeter',
    'have-question': 'Questões?',
    'please-fill-form-send-message': 'Preencha o formulário e envie-nos a sua questão',
    'about-us': 'Sobre Nós',
    'albany-portugal-is-a': 'Albany Portugal é uma empresa líder fornecedora de produtos de mercearia provenientes do Reino Unido',
    'our-brands': 'As nossas marcas',
    'case-size': 'Unidades por caixa',
    'in-basket': 'Carrinho de compras',
    'promotions': 'Promoções',
    'promotions-only': 'Promoções',
    'favourites-only': 'Favoritos',
    'add-to-faves': 'Adicionar aos Favoritos',
    'cancel-notification': 'Remover notificação',
    'add': 'Adicionar ',
    'in-stock': 'em stock',
    'notify-me': 'Notificar-me quando estiver disponível',
    'showing': 'A mostrar',
    'due': 'Disponível até (data curta)',
    'out-of-stock': 'Fora de stock',
    'orders': 'Encomendas',
    'order': 'Encomenda',
    'show-orders': 'Mostrar encomendas',
    'view-products': 'Ver produtos',
    'status': 'Estado da Encomenda',
    'placed': 'Data de encomenda',
    'admin': 'Administrador',
    'select-customer': 'Seleccionar cliente',
    'drag-drop-cat': 'Clique e arraste o catálogo aqui',
    'close': 'Fechar',
    'log-out': 'Sair',
    'special-offers': 'Ofertas Especiais',
    'prev': 'Anterior',
    'next': 'Seguinte',
    'shop-now': 'Comprar agora',
    'intro-offer': 'Oferta Imperdível',
    'now-only': 'Agora por apenas',
    'normal-price': 'Preço Normal',
    'offer-ends-in': 'Oferta expira em',
    'unstickered-price': 'Preço sem rotulagem',
    'customer': 'Cliente',
    'albany-is': 'Albany Portugal é um fornecedor líder de produtos alimentares importados diretamente do Reino Unido',
    'about-albany': 'Sobre Albany',
    'alb-uk-registered': "O Grupo Albany é composto por três organizações comerciais importantes. Albany Products Ltd UK tem fornecido produtos de grandes marcas, marca própria e produtos exclusivos em todo o mundo por mais de 20 anos. Albany Ireland foi fundada em 2012 sob a mesma propriedade privada de Albany Products Limited UK. Albany Portugal, uma sucursal de Albany Products Ltd UK, foi criada em 2015 e opera fornecimentos diretos a retalhistas e distribuidores grossistas em Portugal. Com as suas ligações à Albany Ireland estamos idealmente posicionados para fornecer e oferecer produtos UE-UE sem os atrasos causados por novos controlos transfronteiriços entre o Reino Unido e a UE. O Grupo Albany é composto por três organizações comerciais importantes. Albany Products Ltd UK tem fornecido produtos de grandes marcas, marca própria e produtos exclusivos em todo o mundo por mais de 20 anos. Albany Ireland foi fundada em 2012 sob a mesma propriedade privada de Albany Products Limited UK. Albany Portugal, uma sucursal de Albany Products Ltd UK, foi criada em 2015 e opera fornecimentos diretos a retalhistas e distribuidores grossistas em Portugal. Com as suas ligações à Albany Ireland estamos idealmente posicionados para fornecer e oferecer produtos UE-UE sem os atrasos causados por novos controlos transfronteiriços entre o Reino Unido e a UE.",
    'our-service': 'Nossa oferta',
    'our-service-text': 'Albany Portugal pode fornecer todos os seus requisitos de produtos de marca do Reino Unido e da Irlanda. Os produtos encomendados no nosso site são enviados diretamente da Irlanda e podem ser entregues em qualquer endereço em Portugal. Em alternativa, a sua encomenda pode ser levantada no nosso armazém no Algarve localizado em Caminho do Cerro do Galo (Algarve Express), 8135-028, Almancil. Se tiver algum requisito específico de produtos, envie um e-mail para enquiries@albanyportugal.com.'
}