import React, { useContext } from 'react';
import SlideShow from '../components/prom_slideshow';
import About from '../components/about_box';
import Brands from '../components/brand_marquee';
import ProductRow from '../components/product_row';
import Titled from '../components/titled_segment';
import { AppContext } from '../context';

const Homepage = () => {
    const { strings, shop } = useContext(AppContext);

    const prom_banner_click = id => shop.product_filters.multi_to_one_val(id, 'promotions');

    return(
            <div className="container white">
                <SlideShow action={prom_banner_click} />
                <Titled title={strings['top-picks']}>
                    <ProductRow />
                </Titled>
                <Titled title={strings['about-us']}>
                    <About />
                </Titled>
                <Titled title={strings['our-brands']}>
                    <Brands />
                </Titled>
            </div>
    )
}

export default Homepage;