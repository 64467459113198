import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { AppContext } from '../context';
import { data } from '../dt';
import { Link } from 'react-router-dom'

const ContactForm = props => {
    const [query_success, set_success] = useState(null);
    const { strings } = useContext(AppContext);

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            message: ''
        },
        onSubmit: async values => {
            const { name, email, phone, message } = values;
            const success = await data.user.send_query(name, email, phone, message);
            set_success(success);
        },
        validate: values => {
            const errors = {};
            if (!values.email) {
                errors.email = strings['required']
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = strings['invalid-email']
            }
            if (!values.name) {
                errors.name = strings['required']
            }
            if (!values.message) {
                errors.message = strings['required']
            }
            return errors;
        }
        })

        if (query_success === true) {
            return (
                <div className="signup contact">
                    <div style={{display: 'flex', alignSelf:'stretch', backgroundColor:'#fff', marginTop: 20, flexDirection: 'column', width: '100%'}}>
                        <h2>{strings['thanks']}</h2>
                        <h4>{strings['your-query-submitted']}</h4>
                        <div style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'flex-end', display: 'flex', paddingRight: 10}}>
                            <Link to='/'  className="clear">
                                <div className="button wdth">OK</div>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }

    return (
        <form className="signup contact" onSubmit={formik.handleSubmit}>
            <h3>{strings['have-question']}</h3>

            {props.did_reset === false ? 
                <div className="top_message">
                    {strings['please-fill-form-send-message']}
                </div>
            :
                <div className="top_message">
                    {strings['youve-reset-your']}
                </div>
            }
            <label htmlFor="name" className="required">{strings['name']}</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="name"
                id="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                />
            {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                ) : null}

            <label htmlFor="email" className="required">{strings['email-address']}</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="email"
                id="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
            {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                ) : null}

            <label htmlFor="phone" className="required">{strings['phone-number']}</label>
            <input
                name="phone"
                id="phone"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                    <div className="error">{formik.errors.phone}</div>
                ) : null}

            <label htmlFor="message" className="required">{strings['message']}</label>{/*MISSING*/}
            <textarea
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="message"
                id="message"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.message}
                style={{width: 500}}
                />
            {formik.touched.message && formik.errors.message ? (
                    <div className="error">{formik.errors.message}</div>
                ) : null}

            <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'center'}}>
                <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end', alignItems:'center', width: 500}}>
                    <input style={{display: 'none'}} id='login_submit' className="submit" type="submit" value="Login" />
                    <label htmlFor='login_submit'>
                        <div className="button" htmlFor='login_submit'>{strings['submit']}</div>
                    </label>
                </div>
            </div>

        </form>
    )
}

const ContactScreen = props => {
    const [did_reset, set_did_reset] = useState(false);

    const get_params = () => {
        let url = new URL(window.location.href);
        let did_reset = url.searchParams.get('did_reset');
        set_did_reset(did_reset === '1');
    }

    useEffect(() => {
        get_params();
    })

    return (
        <div className="container contact">
            <div className="row">
                <div className="col">
                    <ul>
                        <li className="heading">Albany Portgual</li>
                        <li>Caminho do Cerro do Galo</li>
                        <li>8135-028 Almancil</li>
                        <li>Portugal</li>
                        <li></li>
                        <li>T: +351 289 313 350</li>
                        <li>M: +351 969 354 615</li>
                    </ul>

                                        
                </div>
                <ContactForm did_reset={did_reset} />
            </div>
        </div>
    )
}


export default ContactScreen;