import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../context';

const AboutBox = props => {
    const { strings } = useContext(AppContext);
    return (
        <div className="home_about_box">
            <div className="image">
                <img alt="Supermarket" src={require('../assets/supermarket.jpg')} />
            </div>
            <div className="detail">
                <span className="header">
                    {strings['albany-portugal-is-a']}
                </span>
                <div className="row">
                    Albany Portugal can supply all your UK branded product requirements. Goods ordered on our website are sent directly from the UK and can be delivered to any address within Portugal.
                </div>
                <div className="row">
                    <Link to='/about' className="clear">
                        <div className="button">{strings['learn-more']}</div>{/*MISSING*/}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AboutBox;