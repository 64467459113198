import { api_url } from '../config';

export const api = async (data, cb=() => {}, endpoint='', headers={}) => {
    const resp = await fetch(api_url+endpoint, {
        method:'POST',
        headers: Object.assign({}, {'Accept': 'application/json', 'Content-Type': 'application/json'}, headers),
        body:JSON.stringify(data)
    })
    const response = await resp.json();
    cb(response);
    return response;
}