import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './landing';
import Shop from './shop';
import Menu from '../components/top_menu';
import Header from '../components/header';
import OrdersScreen from './orders';
import { LinearProgress } from '@material-ui/core'
import { AppContext } from '../context';
import BasketScreen from './basket';
import SignupScreen from './login_create_account';
import LoginScreen from './login';
import Footer from 'rc-footer/lib/index.js';
import ForgotScreen from './forgot';
import ResetScreen from './reset';
import ContactScreen from './contact';
import AboutScreen from './about';
import AdminBar from '../components/admin_bar';
import CookieConsent from 'react-cookie-consent';
import AsCustomer from '../components/as_customer';
import SettingsScreen from './settings';
import PrivacyScreen from './priv';
import TermsScreen from './terms';
import BackOrderModal from '../components/back_order_modal';

const uk_flag = require('../assets/uk_f.png');
const prt_flag = require('../assets/prt_f.png');

const RouterComp = () => {
    const { global_load, rep, language, strings, switch_language, logged_in } = useContext(AppContext)
    return (
        <div className="global">
            <BackOrderModal />
            <div className="container white" style={{position: 'relative'}}>
                <div onClick={switch_language} className="language">
                    <img src={language === 'portuguese' ? uk_flag : prt_flag} width={30} height={30} alt={language === 'portuguese' ? 'View in English' : 'Ver em Português'} />
                    <span>{language === 'portuguese' ? 'View in English' : 'Ver em Português'}</span>
                </div>

                {
                    rep.is_rep === true && <AdminBar />    
                }
                <Router>
                    <Header />
                    <Menu />
                    <Switch>
                        <Route exact path="/">
                            <Landing />
                        </Route>

                        <Route exact path="/shop">
                            <Shop />
                        </Route>
                        
                        <Route exact path="/basket">
                            <BasketScreen />
                        </Route>

                        <Route exact path='/create_account'>
                            <SignupScreen />
                        </Route>

                        <Route exact path="/login">
                            <LoginScreen />
                        </Route>
                        
                        <Route exact path="/orders">
                            <OrdersScreen />
                        </Route>

                        <Route exact path="/forgot">
                            <ForgotScreen />
                        </Route>

                        <Route exact path='/reset'>
                            <ResetScreen />
                        </Route>

                        <Route exact path='/contact'>
                            <ContactScreen />
                        </Route>

                        <Route exact path='/about'>
                            <AboutScreen />
                        </Route>

                        <Route exact path='/settings'>
                            {logged_in && <SettingsScreen />}
                        </Route>

                        <Route exact path='/privacy'>
                            <PrivacyScreen />
                        </Route>
                        
                        <Route exact path='/terms'>
                            <TermsScreen />
                        </Route>

                    </Switch>
                </Router>
            </div>

            {global_load && 
                <div style={{position: 'fixed', bottom: 0, maxWidth: 1000, height: 10, width: '100vw'}}>
                    <LinearProgress />
                </div>
            }

            <AsCustomer rep={rep} />

            <div className="container footer">
                <Footer
                    className="footer"
                    theme='light'
                    columns={[
                        {
                            title: 'Home',
                            url: 'https://portugal-staging.albanyproducts.co.uk',
                            items: [{
                                title:strings['about-us'],
                                url: ''
                                },
                                {
                                title: strings['create-account'],
                                url: 'https://portugal-staging.albanyproducts.co.uk/create_account'
                            },{
                                title: strings['shop'],
                                url: '/shop'
                            }]
                        },
                        {
                            title:strings['contact'],
                            url: '/contact',
                            items: [{
                                title: strings['have-question'],
                                url: '/contact'
                            },{
                                title:strings['terms-conditions'],
                                url:'/terms'
                            },{
                                title: strings['priv-pol'],
                                url: '/privacy'
                            }]
                        },
                        {
                            title: strings['account'],
                            url: '',
                            items:[{
                                title:strings['forgot-password'],
                                url: '/forgot'
                            }]
                        }
                    ]}
                    bottom={
                        <div className="footer_bottom">
                            Albany Portugal  |  Caminho do Cerro do Galo  |  8135-028 Almancil  |  Portugal <br />T: +351 289 313 350 | M: +351 969 354 615<br />
                            {`Copyright Albany Products Ltd ${new Date().getFullYear()}`}
                        </div>
                    }
                    />

                    <CookieConsent
                        location="bottom"
                        enableDeclineButton
                        buttonText={strings['accept']}
                        declineButtonText={strings['decline']}
                        cookieName="accept_cookie"
                        style={{ background: "#2B373B", zIndex: 10000 }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        setDeclineCookie={false}
                        expires={150}
                        >
                        {strings['cookies']}
                    </CookieConsent>
            </div>
        </div>
    )
}

export default RouterComp;