import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { AppContext } from '../context';
import { data } from '../dt';
import { Link } from 'react-router-dom'


const ForgotForm = props => {
    const [did_submit, set_did_submit] = useState(false);
    const { strings } = useContext(AppContext);

    const formik = useFormik({
        initialValues: {
            username: ''
        },
        onSubmit: async values => {
            const success = await data.user.forgot(values.username);
            if (success === true) {
                set_did_submit(true);
            }
        },
        validate: values => {
            const errors = {};
            if (!values.username) {
                errors.username = strings['required'];
            }
            return errors;
        }
        })


    if (did_submit) {
        return (
            <div className="signup">
                <h2>{strings['thanks']}</h2>
                <h3>{strings['you-will-receive']}</h3>
                <Link to='/' className="clear">
                    <div className="button">{strings['home']}</div>
                </Link>
            </div>
        )
    }

    return (
        <form className="signup" onSubmit={formik.handleSubmit}>
            <h3>{strings['forgotten-your-password']}</h3>

            <label htmlFor="username" className="required">Username</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="username"
                id="username"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.username}
                />
            {formik.touched.username && formik.errors.username ? (
                    <div className="error">{formik.errors.username}</div>
                ) : null}

            <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end'}}>
                <input style={{display: 'none'}} id='forgot_submit' className="submit" type="submit" value="Reset Password" />
                <label htmlFor='forgot_submit'>
                    <div className="button" htmlFor='login_submit'>{strings['reset-password']}</div>
                </label>
            </div>

        </form>
    )
}

const LoginScreen = props => {
    return (
        <div className="container">
            <ForgotForm />
        </div>
    )
}

export default LoginScreen;