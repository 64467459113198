import React, { useContext} from 'react';
import { AppContext } from '../context';
import { Link, useLocation } from 'react-router-dom';
import Basket from './basket_button';
import Search from './search_top';

const MenuItem = props => {
    return (
        <div onClick={props.onClick} className={`menu-item ${props.className}`}>
            <span className="name">{props.children}</span>
        </div>
    )
}

const Menu = props => {
    const { logged_in, logout, rep, force_admin, force_admin_bar, show_admin_bar, strings } = useContext(AppContext);
    const route = useLocation();
    return (
        <div className="main_menu">
            <div className="left">
                <Link to="/" className="clear"><MenuItem className={route.pathname === '/' ? 'selected' : ''}>{strings['home']}</MenuItem></Link>
                <Link to="/shop" className="clear"><MenuItem className={route.pathname === '/shop' ? 'selected' : ''}>{strings['shop']}</MenuItem></Link>
                {!logged_in && 
                    <Link to="/create_account" className="clear">
                        <MenuItem className={route.pathname === '/create_account' ? 'selected' : ''}>{strings['create-account']}</MenuItem>
                    </Link>}
                <Link to='/contact' className="clear">
                    <MenuItem className={route.pathname === '/contact' ? 'selected' : ''}>{strings['contact-us']}</MenuItem>
                </Link>
                {logged_in && <Link to='/orders' className="clear"><MenuItem className={route.pathname === '/orders' ? 'selected' : ''}>{strings['orders']}</MenuItem></Link>}

                {rep.is_rep && 1==2 && <Link to='/ukstock' className="clear"><MenuItem className={route.pathname === '/ukstock' ? 'selected' : ''}>UK Stock</MenuItem></Link>}

                {rep.is_rep && show_admin_bar === false && <MenuItem className={force_admin_bar === true ? "admin" : ""} style={force_admin_bar === true ? {backgroundColor: '#6ece78', color:'#fff'} : {}} onClick={() => force_admin(!force_admin_bar)}>{strings['admin']}</MenuItem>}
                
                <Link to='/about' className="clear"><MenuItem className={route.pathname === '/about' ? 'selected' : ''}>{strings['about-us']}</MenuItem></Link>
                
                {logged_in && 
                <Link to="/settings" className="clear">
                    <MenuItem>{strings['settings']}</MenuItem>
                </Link>}
                
                {logged_in && 
                <Link to='/' className="clear">
                    <MenuItem onClick={logout}>{strings['log-out']}</MenuItem>
                </Link>}
            </div>
            <div className="right">
                <Search />
            </div>
            <div className="right">
                <Basket />
            </div>
        </div>
    )
}

export default Menu; 