import React from 'react';
import Modal from 'react-modal';
import { img_url } from '../config';


const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99999
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
}

const AmendModal = props => {
    return (
        <Modal  
            isOpen={props.amendments !== null}
            onRequestClose={props.close}
            style={style}
        >
            {props.amendments !== null && 
                <div className="amendement_modal">
                    <h2>Sorry</h2>
                    <h3>Unfortunately, not all of your products are available</h3>
                    <div className="row" style={{marginBottom: 20}}>
                        <div className="col"><span className="title"></span></div>
                        <div className="col ascol"><span className="title">Product</span></div>
                        <div className="col"><span className="title">Ordered</span></div>
                        <div className="col"><span className="title">Available</span></div>
                    </div>
                    {Object.values(props.amendments).map((a,i) => (
                        <div className="row">
                            <div className="col"><img alt={a.product.description} src={img_url + '/' + a.product.ean + '.jpg'} className="pic" /></div>
                            <div className="col ascol">
                                <span className="title">{a.product.description}</span>
                                <span>{a.product.case} x {a.product.size}</span>
                            </div>
                            <div className="col">{a.ordered}</div>
                            <div className="col">{a.available}</div>
                        </div>
                    ))}
                    <div className="button_row">
                        <div onClick={props.close} className="button">Go Back</div>
                        <div onClick={props.place} className="button">Place Order</div>
                    </div>
                </div>
            }
            
        </Modal>

    )
}

export default AmendModal