import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import { AppContext } from '../context';

const style = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 99999
    },
    overlay: {
        zIndex: 8000,
        backgroundColor: 'rgba(0,0,0,0.5)'
    }
}


const BackOrderModal = props => {
    const { rep } = useContext(AppContext);
    const [qty, set_qty] = useState('');
    const { request_product } = rep;
    const open = request_product.get !== null;

    const close = () => {
        rep.request_product.set(null);
    }

    const submit = () => {
        rep.request_product.send(request_product.get.id, rep.as_customer, qty);
        close();
    }

    return (
        <Modal  
            isOpen={open}
            onRequestClose={close}
            style={style}
            contentLabel="Edit Product"
        >
            <h3>Add Customer Request ({request_product.get !== null ? request_product.get.description: ''})</h3>
            <p>
                Cases:
                <input type="text" value={qty} onChange={e => set_qty(e.target.value)} />
            </p>
            <div className="button" onClick={submit}>Save</div>
           
        </Modal>

    )
}

export default BackOrderModal