import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import Product from '../components/product';
import PromCaro from '../components/prom_slideshow';
import Smenu from '../components/shop_menu';
import TableProduct from '../components/table_product';

const Shop = props => {
    const [current_prom_name ] = useState(null);
    const { shop, shop_view_type } = useContext(AppContext);
    const { can_move_forward, can_move_back, viewing, next, back } = shop;

    const next_button = () => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        next();
    }
    
    console.log(shop_view_type, "SVT")

    return (
        <div className="container">
            <PromCaro shop_page action={(id) => shop.product_filters.multi_to_one_val(id, 'promotions')} small />
            <Smenu />
            <div style={{width: "100%", display:'flex', flexDirection:'column', justifyContent:'center'}}>
                {shop_view_type === 'grid' ? 
                    <div className="grid product">
                        {viewing.map((x,i) => <Product product_id={x} key={i} />)}
                    </div>
                    :
                    <div className="table product">
                        {viewing.map((x,i) => <TableProduct product_id={x} key={i} />)}
                    </div>
                }
            </div>
            <div className="list_footer">
                {can_move_back ? 
                    <div onClick={back} className="button invert margin">
                        Previous Page
                    </div>
                : 
                <span />}
                {can_move_forward ? 
                    <div onClick={next_button} className="button margin">
                        Next Page
                    </div>
                :
                <span />}
            </div>
        </div>
    )
}

export default Shop;