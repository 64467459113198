import React from 'react';

const Header = props => {
    return (
        <div className="main_header">
            <img alt="Albany Portugal" src={require('../assets/logo.jpg')} className="logo" />
        </div>
    )
}

export default Header;