import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { AppContext } from '../context';
import { data } from '../dt';


const ChangePassForm = props => {
    const [status_message, set_status_message] = useState(null);
    const { strings, token } = useContext(AppContext);

    const formik = useFormik({
        initialValues: {
            current_pass: '',
            new_pass: '',
            confirm_pass: ''
        },
        onSubmit: async (values, {resetForm}) => {
           data.user.change_password(token, values.current_pass, values.new_pass, (s) => {
            if (s === true) {
                set_status_message(true);
                resetForm({value: ''});
            } else {
                set_status_message(false);
            }
           })
        },
        validate: values => {
            const errors = {};
            if (!values.current_pass) {
                errors.current_pass = strings['required'];
            }
            if (!values.new_pass) {
                errors.new_pass = strings['required']
            }  else if (values.new_pass.length < 8) {
                errors.new_pass = "Password must be 8+ characters"
            } else if (/[0-9]/.test(values.new_pass) === false || /[A-Z]/.test(values.new_pass) === false || /[a-z]/.test(values.new_pass) === false) {
                errors.passwnew_passord = "Password must contain A-Z, a-z and numbers"
            }

            if (values.confirm_pass !== values.new_pass) {
                errors.confirm_pass = 'Must match new password'
            }
            return errors;
        }
        })


    return (
        <form className="reset_pass" onSubmit={formik.handleSubmit}>
            {status_message !== null ?
                status_message === true ? 
                    <span className="success">You've successfully reset your password</span>
                :
                    <span className="failure">Something went wrong. Please check your current password</span>

                : null
            }
            <label htmlFor="current_pass">Current Password</label>
            <input
                onBlur={formik.handleBlur}
                name="current_pass"
                id="current_pass"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.current_pass}
                />
            {formik.touched.current_pass && formik.errors.current_pass ? (
                    <div className="error">{formik.errors.current_pass}</div>
                ) : null}

            <label htmlFor="new_pass">New Password</label>
            <input
                onBlur={formik.handleBlur}
                name="new_pass"
                id="new_pass"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.new_pass}
                />
            {formik.touched.new_pass && formik.errors.new_pass ? (
                    <div className="error">{formik.errors.new_pass}</div>
                ) : null}


            <label htmlFor="confirm_pass">Confirm Password</label>
            <input
                onBlur={formik.handleBlur}
                name="confirm_pass"
                id="confirm_pass"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.confirm_pass}
                />
            {formik.touched.confirm_pass && formik.errors.confirm_pass ? (
                    <div className="error">{formik.errors.confirm_pass}</div>
                ) : null}


            <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end'}}>
                <input style={{display: 'none'}} id='forgot_submit' className="submit" type="submit" value="Reset Password" />
                <label htmlFor='forgot_submit'>
                    <div className="button" htmlFor='login_submit'>{strings['reset-password']}</div>
                </label>
            </div>

        </form>
    )
}

const NotifyForm = props => {
    const { user, token } = useContext(AppContext);
    const [accept_emails, set_accept_email] = useState(user.accepts_emails);

    const toggle = () => {
        data.user.notification_prefs(token, !accept_emails);
        set_accept_email(!accept_emails)
    }
    
    return (
        <form className="notify">
            <label htmlFor="accept_email">Allow Notification emails</label>
            <input type="checkbox" checked={accept_emails} onClick={toggle} />
        </form>
    )
}

const SettingsScreen = props => {
    const { user } = useContext(AppContext);
    return (
        <div className="container">
            <div className="signup settings">
                <div className="row">
                    <div className="col">
                        <span className="title">Password</span>
                        {user && <ChangePassForm />}
                    </div>
                    <div className="col">
                        <span className="title">Notifications</span>
                        {user && <NotifyForm />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsScreen;