import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { data } from '../dt';
import { Redirect } from 'react-router-dom'

const ResetForm = props => {
    const formik = useFormik({
        initialValues: {
            conf_password: '',
            password: ''
        },
        onSubmit: values => {
            data.user.reset_pass(props.token, values.password, (success) => {
                if (success) {
                    props.reset()
                }
            })
        },
        validate: values => {
            const errors = {};
            if (!values.password) {
                errors.password = 'Required';
            } else if (values.password.length < 8) {
                errors.password = "Password must be 8+ characters"
            } else if (/[0-9]/.test(values.password) === false || /[A-Z]/.test(values.password) === false || /[a-z]/.test(values.password) === false) {
                errors.password = "Password must contain A-Z, a-z and numbers"
            }
            if (!values.conf_password) {
                errors.conf_password = "Required"
            } else if (values.conf_password !== values.password) {
                errors.conf_password = "Must match password"
            }
            return errors;
        }
        })


    return (
        <form className="signup" onSubmit={formik.handleSubmit}>
            <h3>{props.is_create === true ? 'Create' : 'Reset'} your password</h3>

            <div className="top_message">
                Please create a new password below
            </div>

            <label htmlFor="password" className="required">Password</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="password"
                id="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                />
            {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                ) : null}

            <label htmlFor="conf_password" className="required">Confirm Password</label>
            <input
                name="conf_password"
                id="conf_password"
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.conf_password}
                />
                {formik.touched.conf_password && formik.errors.conf_password ? (
                    <div className="error">{formik.errors.conf_password}</div>
                ) : null}

            <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'center'}}>
                <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end', alignItems:'center', width: 500}}>
                    <input style={{display: 'none'}} id='reset_submit' className="submit" type="submit" value="Reset" />
                    <label htmlFor='login_submit'>
                        <div onClick={formik.handleSubmit} className="button" htmlFor='reset_submit'>Reset</div>
                    </label>
                </div>
            </div>

        </form>
    )
}

const ResetScreen = props => {
    const [did_reset, set_did_reset] = useState(false);
    const [is_create, set_create] = useState(false);
    const [token, set_token] = useState(null);

    const get_params = () => {
        let url = new URL(window.location.href);
        let token = url.searchParams.get('token');
        let is_create = url.searchParams.get('create');
        if (is_create !== null) {
            set_create(true);
        }
        set_token(token);
    }

    useEffect(() => {
        get_params();
    })

    const reset = () => {
        set_did_reset(true);
    }

    if (did_reset) {
        return (
            <Redirect to={`/login?did_reset=1${is_create === true ? '&create=1' : ''}`} />
        )
    }

    if (token === null) {
        return (
            <div className="container">
                Something went wrong
            </div>
        )
    }

    return (
        <div className="container">
            <ResetForm token={token} reset={reset} is_create={is_create} />
        </div>
    )
}

export default ResetScreen;