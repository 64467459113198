import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { img_url } from '../config';
import Stock from '../components/stock_indicator';
import { data } from '../dt'; 
import AmendModal from '../components/amendments_modal';
import { Redirect, Link } from 'react-router-dom';

const pad = x => {
    x = x.toString()
    while (x.length < 2) {
        x = '0' + x;
    }
    return x
}


const BasketItem = props => {
    const { basket_counts, stocks_override, add_to_basket, set_oos_product, following, follow, strings, language } = useContext(AppContext);
    const [basket_count, set_basket_count] = useState(0);
    const [unpushed_basket_count, set_ubc] = useState(0);
    const [changes_disabled, disable_changes] = useState(false);
    const [push_basket_timer, set_pbt] = useState(null);
    const [forced_basket_count, set_fbc] = useState(null);
    const [has_been_forced, set_hbf] = useState(false);
    const [available, set_available] = useState(0);
    const { item } = props;
    const { product } = item;

    useEffect(() => {
        let server_available = product.id in stocks_override ? stocks_override[product.id].stock : product.current_stock;
        if (server_available !== null) {
            if (basket_count > server_available) {
                set_fbc(server_available);
                set_hbf(true);
                add_to_basket(product, server_available, () => {})
            }
        }
    }, [basket_count, stocks_override, product.id, product.current_stock, product, add_to_basket])

    useEffect(() => {
        let server_available = product.id in stocks_override ? stocks_override[product.id].stock : product.current_stock;
        if (server_available === null) {
            set_available(null);
        } else {
            set_available(server_available - basket_count - unpushed_basket_count);
        }
    }, [product.id, stocks_override, basket_count, unpushed_basket_count, product.current_stock])

    useEffect(() => {
        set_basket_count(product.id in basket_counts ? basket_counts[product.id] : 0);
    }, [basket_counts, set_basket_count, product])

    if (basket_count + unpushed_basket_count === 0) {
        return null;
    }

    const is_following = following.indexOf(product.id) !== -1;
    const toggle_follow = () => follow(product.id, following.indexOf(product.id) === -1);

    const add_to_basket_if_stock = () => {
        if (changes_disabled === false) {
            if (available === null || available > 0) {
                if (push_basket_timer !== null) {
                    clearTimeout(push_basket_timer);
                }
                set_pbt(setTimeout(() => {
                    disable_changes(true);
                    add_to_basket(product, basket_count + unpushed_basket_count + 1, () => {
                        set_ubc(0);
                        disable_changes(false);
                    })
                }, 1000));
                set_ubc(unpushed_basket_count + 1)
            } else {
                set_oos_product(product);
            }
        }
    }

    const remove_from_basket_if_stock = () => {
        let server_available = product.id in stocks_override ? stocks_override[product.id].stock : product.current_stock;
        if (changes_disabled === false) {
            if (basket_count + unpushed_basket_count > 0) {
                if (push_basket_timer !== null) {
                    clearTimeout(push_basket_timer);
                }
                set_pbt(setTimeout(() => {
                    disable_changes(true);
                    add_to_basket(product, basket_count + unpushed_basket_count - 1, () => {
                        set_ubc(0);
                        disable_changes(false);
                    })
                }, 1000));
                set_ubc(unpushed_basket_count - 1)
                if (server_available === null || basket_count + unpushed_basket_count - 1 < server_available) {
                    set_fbc(null);
                }
            }
        }
    }
    
    const price = () => {
        const prod = props.item.product;
        if (prod.prom_price) {
            return (
                <div className="prom price"><span className="compare price">{language === 'english' && '€'}{prod.price.toFixed(2)}</span> {prod.prom_price.toFixed(2)}{language === 'portuguese' && '€'}</div>            )
        }
        return (
            <div className="price">{language === 'english' && '€'}{((basket_count + unpushed_basket_count) * item.price).toFixed(2)}{language === 'portuguese' && '€'}</div>
        )
    }

    let server_available = product.id in stocks_override ? stocks_override[product.id].stock : product.current_stock;

    return (
        <div className="item basket">
            {server_available !== null && server_available <= 0 && 
            <div className="unavailable">{strings['sorry-now-una']}
            <div className="notify_text">
                    {strings['notify-me']}
                    <input type="checkbox" checked={is_following} onClick={toggle_follow} />
                </div>
            </div>}

            {product.prom_price && <div className="prom-triangle" />}
            <div className="img-container basket">
                <img src={img_url + '/' + item.product.ean + '.jpg'} className="product-img basket" alt={item.product.description} />
            </div>

            <div className="detail">
                <div className="top">
                    <span className="title">{item.product.description}</span>
                    <span className="case-size">{strings['case-size']} {item.product.case}</span>
                </div>
                <div className="mid">
                    {has_been_forced && server_available !== null ? 'Qty Adjusted - only ' + server_available + ' available' : null}
                </div>
                <div className="btm">
                    <Stock stock={available} />
                    <div className="case_qty">
                        <FaMinus onClick={remove_from_basket_if_stock} size={24} color='grey' style={{cursor: 'pointer'}} />
                        {forced_basket_count === null ? basket_count + unpushed_basket_count : forced_basket_count}
                        <FaPlus onClick={add_to_basket_if_stock} size={24} color='#ff9800' style={{cursor: 'pointer'}} />
                    </div>
                    {price()}
                </div>
            </div>
        </div>
    )
}


const BasketScreen = props => {
    const { basket, place_order, logged_in, rep, strings, token } = useContext(AppContext);
    const [did_place_order, set_did_place] = useState(null);
    const [show_amendments_modal, set_show_amendments_modal] = useState(null);
    const [noi, set_noi] = useState(null);

    if (logged_in === false) {
        return (
            <Redirect to='/login?frm=basket' />
        )
    }

    if (did_place_order !== null) {
        const just_placed = did_place_order;
        return (
            <Redirect to={'/orders?thanks=1&order=' + just_placed} />
        );
    }

    const price_total = basket.map(x => x.quantity * x.price).reduce((a,b) => a + b, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const cases = basket.map(x => x.quantity).reduce((a,b) => a + b,0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    const do_place_order = () => {
        if (basket.length > 0) {
            place_order((success, new_order_id, amendments) => {
                if (Object.keys(amendments).length > 0) {
                    set_noi(new_order_id);
                    set_show_amendments_modal(amendments);
                } else {
                    if (success === true) {
                        set_did_place(new_order_id);
                    }    
                }
            });
        }
    }


    const do_place_order_new = () => {
        if (basket.length > 0) {
            if (rep.as_customer !== null) {
                data.rep.is_order_ok(token, rep.as_customer, false, (is_ok, issues) => {
                    if (is_ok === true) {
                        place_order((success, new_order_id, amendments) => {
                            set_did_place(new_order_id);
                            set_show_amendments_modal(null);
                        });
                    } else {
                        set_show_amendments_modal(issues);
                    }
                })
            } else {
                data.orders.is_ok(token, false, (is_ok, issues) => {
                    if (is_ok === true) {
                        place_order((success, new_order_id, amendments) => {
                            set_did_place(new_order_id);
                            set_show_amendments_modal(null);
                        })
                    } else {
                        set_show_amendments_modal(issues);
                    }
                })
            }
        }
    }

    const amend_and_place = () => {
        if (rep.as_customer !== null) {
            data.rep.is_order_ok(token, rep.as_customer, true, (is_ok, issues) => {
                do_place_order_new();
            })
        } else {
            data.orders.is_ok(token, true, (is_ok, issues) => {
                do_place_order_new();
            })
        }
    }

    return (
        <div style={{width: '100%'}}>
            <div className="order_box">
                <div className="header">{strings['order']}</div>
                <div className="datapoint">Products: {basket.length}</div>
                <div className="datapoint">Cases: {cases}</div>
                <div className="datapoint">Total: {price_total}€</div>
                <div onClick={do_place_order} className={`button${basket.length === 0 ? ' disabled' :''}`}>
                    {strings['place-order']}
                </div>
            </div>
            <div className="container">
                <div className="grid basket">
                    {basket.map((x,i) => <BasketItem item={x} key={i} />)}
                </div>

                {basket.length === 0 && <div className="signup">
                    {rep.as_customer !== null ? 'Your basket for this customer is empty' : 'Your basket is empty'}
                    <Link to='/shop' className="clear">
                        <div className="button" style={{marginTop: 10}}>{strings['browse-products']}</div>    
                    </Link>
                </div>}

                <div style={{height: 200}}>

                </div>                
            </div>
            <AmendModal amendments={show_amendments_modal} place={amend_and_place} close={() => {
                set_show_amendments_modal(null);
            }} />
        </div>
    )
}

export default BasketScreen;