import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { data } from '../dt';
import { Link } from 'react-router-dom';
import { AppContext } from '../context';

const SignupForm = props => {
    const [did_submit, set_did_submit] = useState(false);
    const { strings } = useContext(AppContext);

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phone: '',
            business_name: '',
            website: '',
            address: ''
        },
        onSubmit: values => {
            data.signup.submit(values, () => set_did_submit(true));
        },
        validate: values => {
            const errors = {};
            if (!values.email) {
                errors.email = strings['required']
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = strings['invalid-email']
            }
            if (!values.name) {
                errors.name = strings['required']
            }
            if (!values.address) {
                errors.address = strings['required']
            }
            return errors;
        }
        })

    if (did_submit === true) {
        return (
            <div className="signup">
                <div style={{display: 'flex', alignSelf:'stretch', backgroundColor:'#fff', marginTop: 20, flexDirection: 'column', width: '100%'}}>
                    <h2>{strings['thanks']}!</h2>
                    <h4>Your details have been submitted and someone we'll be in touch soon to complete your application</h4>
                    <div style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'flex-end'}}>
                        <Link to='/' className="clear">
                            <div className="button">OK</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <form className="signup" onSubmit={formik.handleSubmit}>
            <h3>{strings['apply-for-account']}</h3>
            <div className="top_message">
                {strings['already-have-account']}? <Link className="inline_link" to='/login'>{strings['click-here']}</Link> to login
            </div>
            <label htmlFor="email" className="required">{strings['email-address']}</label>
            <input
                autoFocus={true}
                onBlur={formik.handleBlur}
                name="email"
                id="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                />
            {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                ) : null}
            

            <label htmlFor="name" className="required">{strings['your-name']}</label>
            <input
                name="name"
                id="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                ) : null}
            
            <label htmlFor="business_name">{strings['business-name']}</label>
            <input
                name="business_name"
                id="business_name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_name}
                />

            <label htmlFor="phone">{strings['phone-number']}</label>
            <input
                name="phone"
                id="phone"
                type="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                />

            <label htmlFor="website">{strings['website']}</label>
            <input
                name="website"
                id="website"
                type="website"
                onChange={formik.handleChange}
                value={formik.values.website}
                onBlur={formik.handleBlur}
                />
            
            <label htmlFor="address" className="required">{strings['address']}</label>
            <input
                name="address"
                id="address"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.address}
                onBlur={formik.handleBlur}
                />
                
                {formik.touched.address && formik.errors.address ? (
                    <div className="error">{formik.errors.address}</div>
                ) : null}
            
            <div style={{alignSelf:'stretch', display: 'flex', justifyContent:'flex-end'}}>
                <input type="submit" id="sign_up_submit" style={{display: 'none'}} />
                <label htmlFor='sign_up_submit'>
                    <div className="button">{strings['submit']}</div>
                </label>
            </div>

        </form>
    )
}



const SignupScreen = props => {
    return (
        <div className="container">
            <SignupForm />
        </div>
    )
}

export default SignupScreen;