import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AppContext } from '../context';
import Select from 'react-select';
import NumericInput from 'react-numeric-input';
import { useDropzone } from 'react-dropzone';
import Loader from 'react-loader-spinner';

const select_styles = {
    menu: (provided, state) => ({
        ...provided,
        width: 200,
        zIndex: 6000,
        color: '#444'
    }),
    control: (provided, state) => ({
        ...provided,
        width: 200,
        height: 20,
        zIndex: 6000,
        color: '#444'
    })
}

const CatalogueUpload = props => {
    const [loading, set_loading] = useState(false);
    const [error, set_error] = useState(false);
    const [rejects, set_rejects] = useState(false);
    const { rep, strings } = useContext(AppContext);
    

    const onDrop = useCallback(acceptedFiles => {
        set_error(false);
        set_rejects(false);
        try {
            const f = acceptedFiles[0];
            if (!(f.name.endsWith('.xlsx'))) {
                set_error(true);
            } else {
                set_loading(true);
                rep.upload_catalogue(f, (success, rejects) => {
                    if (success === true && rejects === false) {
                        rep.hard_catalogue_reset();
                        set_loading(false);
                        set_error(false);
                    } else if (success === true && rejects === true) {
                        set_loading(false);
                        set_rejects(true);
                    } else {
                        set_loading(false)
                        set_error(true);
                    }
                })
            }
        } catch(e) {
            set_error(true);
        }
    }, [rep])
    
    const { getRootProps, getInputProps } = useDropzone({onDrop});

    if (loading === true) {
        return (
            <div className="catalogue_dropzone center">
                <Loader type="Puff" color='#FAC043' height={50} width={50} />
            </div>
        )
    }
    
    return (
        <div className="catalogue_dropzone">
            <div className={`tst${error || rejects ? ' error' : ''}`} {...getRootProps()}>
                <input {...getInputProps} />
                {
                    rejects ? 'File rejected - please correct errors and try again' :
                    error ? strings['invalid-file'] : strings['drag-drop-cat']
                }
            </div>
        </div>
    )
}


const AdminBar = props => {
    const { rep, show_admin_bar, force_admin_bar, force_admin, strings } = useContext(AppContext);
    const [reports_list, set_reports_list] = useState([]);
    const [customers, set_customers] = useState([]);
    const [current_customer, set_current_customer] = useState(null);
    const [override, set_override] = useState(1.01);
    const [push_override_timer, set_override_timer] = useState(null);
    const [stats, set_stats] = useState(null);
    const [customer_select_val, set_customer_select_val] = useState(null);
    const [selected_report, set_selected_report] = useState(null);
    const [report_loading, set_report_loading] = useState(false);
    const [customer_open, set_customer_open] = useState(false);

    useEffect(() => {
        async function getstats() {
            const stats = await rep.get_customer_stats(current_customer);
            set_stats(stats);
        }
        if (current_customer !== null) {
            getstats();
        }
    }, [current_customer, rep])

    const adjust_override = (e) => {
        if (push_override_timer !== null) {
            clearTimeout(push_override_timer);
        }
        set_override(e)

        const override = e;
        const cust = current_customer;

        set_override_timer(setTimeout(() => rep.set_override(override, cust), 500));
    }

    useEffect(() => {
        let c = Object.values(rep.customers).map(x => ({value: x.id, label: x.username}));
        c = c.sort((a, b) => (a.label > b.label) ? 1 : -1)
        set_customers(c);
    }, [rep.customers])

    useEffect(() => {
        let reps = rep.reports.reports.map(x => ({value: x.key, label: x.name}));
        reps = reps.sort((a,b) => (a.label > b.label) ? 1 : -1);
        set_reports_list(reps);
    }, [rep.reports.reports]);

    useEffect(() => {
        if (rep.as_customer !== current_customer) {
            set_current_customer(rep.as_customer);
            rep.get_override(rep.as_customer, (override) => set_override(override));
        }
    }, [rep.as_customer, current_customer, rep])

    if (show_admin_bar === false && force_admin_bar === false) {
        return null;
    }

    const download_catalogue = () => {
        rep.download_catalogue()
    }

    const download_bs = () => {
        rep.download_bs()
    }

    const select_customer = e => {
        set_customer_select_val(e);
        rep.shop_as_customer(e.value);
    }

    const clear_customer = e => {
        set_customer_select_val(null);
        rep.shop_as_customer(null);
    }

    const select_report = e => {
        set_selected_report(e);
    }

    const download_report = key => {
        set_report_loading(true);
        rep.reports.download_report(key, () => set_report_loading(false), {});
    }

    return (
            <div className={`admin_bar side`}>
                {force_admin_bar === true && <span onClick={() => force_admin(false)} className="close">{strings.close}</span>}
                <div className="top">
                    <div className="section">
                        <span className="sort_label">{strings.customer}</span>
                        <Select
                            defaultValue={strings['select-customer']}
                            value={customer_select_val}
                            onChange={select_customer}
                            styles={select_styles}
                            options={customers}
                            placeholder={"Select Customer"}
                            onMenuOpen={() => set_customer_open(true)}
                            onMenuClose={() => set_customer_open(false)}
                            />
                        {rep.as_customer !== null && <div style={{marginTop: 10}} onClick={clear_customer} className="button">Deselect Customer</div>}
                    </div>


                    {rep.as_customer !== null && 
                        <div className="section">
                            <span className="sort_label">{strings['price-override']}</span>{/*MISSING*/}
                            <NumericInput className="price_override_input" step={0.01} precision={2} value={override} onChange={e => adjust_override(e)} />
                        </div>
                    }

                    {rep.as_customer !== null && stats !== null &&
                        <div className="section stats">
                            <span className="title">3 Month Summary</span>
                            <div className="row">
                                <span className="label"># Orders</span>
                                <span className="value">{stats.count}</span>
                            </div>
                            <div className="row">
                                <span className="label">Value €</span>
                                <span className="value">{stats.val === 0 ? 0 : stats.val.toFixed(2)}</span>
                            </div>
                            <div className="row">
                                <span className="label">Avg / Order €</span>
                                <span className="value">{stats.avg === 0 ? 0 : stats.val.toFixed(2)}</span>
                            </div>
                        </div>}

                    {customer_open === false && <div className="section reports">
                        <span className="title">Reports</span>
                        <Select
                            defaultValue={'Select a report'}
                            value={selected_report}
                            onChange={select_report}
                            styles={select_styles}
                            options={reports_list}
                            placeholder='Select Report'
                        />
                        {selected_report !== null && <div style={{marginTop: 10}} onClick={() => download_report(selected_report.value)} className="button">{report_loading === true ? 
                            <Loader type="Puff" color='#FAC043' height={20} width={20} />
                        : 'Download'}</div>}
                    </div>}
                </div>
                <div className="bottom">
                    <div onClick={download_bs} style={{padding: 30, display:'flex', flexDirection:'row', alignItems:'center', cursor:'pointer'}}>
                        Bulk Stock Report
                    </div>
                    <div className="section bottom">
                        <CatalogueUpload />
                    </div>

                    <div className="section">
                        <span className="download" onClick={download_catalogue}>
                            {strings['download-cat']}
                        </span>
                    </div>
                </div>

            </div>
    )
}

export default AdminBar;