import React, { useContext } from 'react';
import { AppContext } from '../context';

const Stock = props => {
    const { stock } = props;
    const { strings } = useContext(AppContext);

    if (stock === null || true) {
        return null;
    }
    
    let stk = stock;

    let class_name = 'ok';
    let message = (stk === null ? 0 : stk > 500 ? '500+' : stk) + ' ' + strings['in-stock'];

    if (stk === 0 || stk === null) {
        return null
    } else if (stk < 10) {
        class_name = 'low';
    }

    return (
        <div className={`stock_indicator ${class_name}`}>{message}</div>
    )
}

export default Stock;