import React, {useContext} from 'react';
import { AppContext } from '../context';

const PrivacyScreen = props => {
    const { language } = useContext(AppContext);
    if (language === 'portuguese') {
        return (
            <div className="container">
                <div className="signup">
    
                <h1>Política de privacidade para Albany Products Ltd</h1>
    
                    <p>Em Albany Portugal, acessível a partir de https://www.albanyportugal.com ou https://www.albanyportugal.eu, uma das nossas principais prioridades é a privacidade dos nossos visitantes. Este documento de Política de Privacidade contém tipos de informação que são recolhidos e registados por Albany Portugal e como a usamos.</p>
    
                    <p>Se você tiver perguntas adicionais ou precisar de mais informações sobre a nossa Política de Privacidade, não hesite em nos contatar.</p>
    
                    <h2>Arquivos de log</h2>
    
                    <p>Albany Portugal segue um procedimento padrão de uso de arquivos de log. Esses arquivos registam os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e fazem parte das análises dos serviços de hospedagem. As informações recolhidas pelos arquivos de log incluem endereços de protocolo de Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência / saída e possivelmente o número de cliques. Eles não estão vinculados a nenhuma informação de identificação pessoal. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas.</p>
    
                    <h2>Cookies e Web Beacons</h2>
    
                    <p>Como qualquer outro site, Albany Portugal usa 'cookies'. Esses cookies são usados para armazenar informações, incluindo as preferências dos visitantes e as páginas do site que o visitante acedeu ou visitou. As informações são usadas para otimizar a experiência dos usuários, personalizando o conteúdo da nossa página da web com base no tipo de navegador dos visitantes e / ou outras informações.</p>
    
                    <p>Para obter mais informações gerais sobre cookies, leia  <a href="https://www.cookieconsent.com/what-are-cookies/">"O que são cookies"</a>.</p>
    
                    <p>Você pode optar por desabilitar os cookies por meio das opções individuais do navegador. Para saber informações mais detalhadas sobre o gerenciamento de cookies com navegadores da web específicos, elas podem ser encontradas nos respectivos sites dos navegadores.</p>
    
                    <h2>Política de privacidade online apenas</h2>
    
                    <p>Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para os visitantes do nosso site no que diz respeito à informação que partilham e / ou recolhem de Albany Portugal. Esta política não se aplica a nenhuma informação recolhida offline ou por meio de outros canais que não este site.</p>
    
                    <h2>Consentimento</h2>
    
                    <p>Ao utilizar o nosso website, você concorda com nossa Política de Privacidade e concorda com os seus Termos e Condições.</p>
                </div>
            </div>
        )
    }
    return (
        <div className="container">
            <div className="signup">

            <h1>Privacy Policy for Albany Products Ltd</h1>

                <p>At Albany Portugal, accessible from https://www.albanyportugal.com or https://www.albanyportugal.eu, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Albany Portugal and how we use it.</p>

                <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

                <h2>Log Files</h2>

                <p>Albany Portugal follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                <h2>Cookies and Web Beacons</h2>

                <p>Like any other website, Albany Portugal uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

                <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies"</a>.</p>

                <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                <h2>Online Privacy Policy Only</h2>

                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Albany Portugal. This policy is not applicable to any information collected offline or via channels other than this website.</p>

                <h2>Consent</h2>

                <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
            </div>
        </div>
    )
}


export default PrivacyScreen;