import React, { useContext } from 'react';
import Carousel from 'nuka-carousel';
import { Link } from 'react-router-dom';
import { img_url } from '../config'
import { AppContext } from '../context';

const Promotion = props => {
    const { prom } = props;
    const { locality } = useContext(AppContext);


    if (props.shop_page === true) {
        return (
            <div onClick={() => props.action(prom.id)} style={{height: 116, width: '100%'}}>
                <img alt={prom.name} src={img_url + '/' + (locality === 'english' ? prom.banner_s_e : prom.banner_s_p)} className='carousel_prom_small' />
            </div>
        )
    }

    if (props.small === true) {
        return (
            <Link to={`/shop`}>
                <div onClick={() => props.action(prom.id)} style={{height: 116, width: "100%"}}>
                    <img alt={prom.name} src={img_url + '/' + (locality === 'english' ? prom.banner_s_e : prom.banner_s_p)} className='carousel_prom_small' />
                </div>
            </Link>
        )
    }
    return (
        <Link to={`/shop`}>
            <div onClick={() => props.action(prom.id)} style={{height: 582, width: "100%"}}>
                <img alt={prom.name} src={img_url + '/' + (locality === 'english' ? prom.banner_l_e : prom.banner_l_p)} className="carousel_prom img" />
            </div>
        </Link>
    )
}

const SlideShow = props => {
    const { promotions, strings } = useContext(AppContext);
    return (
       <Carousel defaultControlsConfig={{nextButtonText: strings['next'], prevButtonText: strings['prev']}} wrapAround autoplay={true} autoplayInterval={4000} style={{height: props.small === true ? 116 : 582}} >
           {promotions.map((p,i) => <Promotion shop_page={props.shop_page} action={props.action} small={props.small} prom={p} key={i} />)}
       </Carousel>
    )
}

export default SlideShow