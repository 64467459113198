import React, { useContext } from 'react';
import Product from './product';
import { Link } from 'react-router-dom';
import { AppContext } from '../context';

const ProductRow = props => {
    const { top_picks, strings } = useContext(AppContext);
    return (
        <div className="product-row-holder">
            <div className="grid product">
                {top_picks.map((x,i) => <Product key={i} product_id={x} />)}
            </div>
            <div className="list_footer">
                <span />
                <Link to='/shop' className="clear">
                    <div className="button">{strings['more-products']}</div>
                </Link>
            </div>
        </div>
    )
}

export default ProductRow;