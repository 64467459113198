import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import { img_url } from '../config';
import { FaHeart, FaPlus, FaMinus, FaBox } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { FaShoppingBasket } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import Stock from './stock_indicator';


const ProductPrice = props => {
    const { strings, language } = useContext(AppContext)
    const { pricing, case_size } = props;
    if (!pricing) {
        return null;
    }
    if (!pricing.price) {
        return null;
    }
    let price = pricing.price.toFixed(2);
    let unit_price = (pricing.price / case_size).toFixed(2);
    
    if (pricing.prom_price === null) {
        return (
            <div style={{flexDirection:'column'}}>
                <span className="case">{language === 'english' && '€'}{price}{language === 'portuguese' && '€'}</span>
                <span className="unit">{unit_price}€ /{strings['unit']}</span>
            </div>);
    }

    let prom_price = pricing.prom_price.toFixed(2);
    let prom_unit_price = (pricing.prom_price / case_size).toFixed(2);
    
    return (
        <div style={{flexDirection: 'column'}}>
            <span className="case prom">
                {language === 'english' && '€'}{prom_price}{language === 'portuguese' && '€'}<span className='case compare'>{language === 'english' && '€'}{price}{language === 'portuguese' && '€'}</span></span>
            <span className="unit prom">{language === 'english' && '€'}{prom_unit_price}{language === 'portuguese' && '€'} <span className='unit compare'>{language === 'english' && '€'}{unit_price}{language === 'portuguese' && '€'}</span></span>
        </div>
    )
}

const Product = props => {
    const [current_product_id, set_current_product_id] = useState(null);
    const [is_following, set_following] = useState(false);
    const [is_fave, set_is_fave] = useState(false);
    const [basket_count, set_basket_count] = useState(0);
    const [unpushed_basket_count, set_ubc] = useState(0);
    const [push_basket_timer, set_pbt] = useState(null);
    const [changes_disabled, disable_changes] = useState(false);
    const [product, set_product] = useState(false);
    const { products_lib_tick, products_library, strings, logged_in, add_to_basket, basket_counts, toggle_fave_product, following, follow, set_product_in_view, stocks_override, rep, set_oos_product, current_prom_id } = useContext(AppContext)
    const [available, set_available] = useState(0);
    const [pricing, set_pricing] = useState({});
    const [has_requested, set_req] = useState(false);
    const [missing_img, set_missing_img] = useState(false);

    useEffect(() => {
        if (rep.is_rep === true) {
            if (rep.as_customer in rep.product_requests) {
                const rq_ind = rep.product_requests[rep.as_customer].map(x => x.id).indexOf(product.id);
                if (rq_ind !== -1) {
                    const qty = rep.product_requests[rep.as_customer][rq_ind].quantity;
                    if (qty !== has_requested) {
                        set_req(qty);
                    }
                } else {
                    if (has_requested !== false) {
                        set_req(false);
                    }
                }
            }
        } else {
            if (has_requested !== false) {
                set_req(false);
            }
        }
    }, [rep, rep.as_customer, product])
    const { product_id } = props;

    useEffect(() => {
        if (product_id in products_library) {
            set_product(products_library[product_id]);
        }
    }, [product_id, products_library, products_lib_tick])
    
    useEffect(() => {
        if (product) {
            let pricing = {price: product.price, prom_price: product.prom_price};
           
            if (rep.as_customer !== null) {
                pricing = rep.pricing[rep.as_customer][product.id];
            }
            set_pricing(pricing);
        }
    }, [product, rep.pricing, rep.as_customer, rep.pricing_tick])
    
    useEffect(() => {
        let current_stock = 0;
        if (product) {
            current_stock = product.current_stock;
        }
        let server_available = product_id in stocks_override ? stocks_override[product_id].stock : current_stock;
        if (server_available === null) {
            set_available(null)
        } else {
            set_available(server_available - basket_count - unpushed_basket_count);
        }
    }, [product, stocks_override, basket_count, unpushed_basket_count, product.current_stock, product_id]);

    useEffect(() => {
        if (current_product_id !== null) {
            set_product_in_view(current_product_id, false);
        }
        set_current_product_id(product_id);
        set_product_in_view(product_id, true);
    }, [current_product_id, set_product_in_view, product_id])
    
    useEffect(() => {
        set_basket_count(product_id in basket_counts ? basket_counts[product_id] : 0);
    }, [basket_counts, set_basket_count, product_id])

    useEffect(() => {
        if (rep.follows !== null) {
            set_following(rep.follows.indexOf(product_id) !== -1);
        } else {
            set_following(following.indexOf(product_id) !== -1);
        }
    }, [rep.follows, following, product_id, rep.tick])

    useEffect(() => {
        if (rep.faves !== null) {
            set_is_fave(rep.faves.indexOf(product_id) !== -1);
        } else {
            set_is_fave(product.favourite);
        }
    }, [rep.faves, product_id, product.favourite, rep.tick])
    

    const toggle_follow = () => {
        if (rep.as_customer === null) {
            follow(product_id, following.indexOf(product_id) === -1)
        } else {
            rep.request_product.set(product);
        }
    }

    const toggle_fave = () => {
        if (rep.as_customer === null) {
            toggle_fave_product(product);
        } else {
            rep.toggle_fave(product_id, !is_fave);
        }
    }

    const add_to_basket_if_stock = () => {
        if (changes_disabled === false) {
            if (available === null || available > 0) {
                if (push_basket_timer !== null) {
                    clearTimeout(push_basket_timer);
                }
                set_pbt(setTimeout(() => {
                    disable_changes(true);
                    add_to_basket(
                        product, basket_count + unpushed_basket_count + 1, () => {
                            disable_changes(false);
                            set_ubc(0);
                        })
                }, 400));
                set_ubc(unpushed_basket_count + 1);
            } else {
                set_oos_product(product);
            }
        }
    }
    
    const remove_from_basket_if_stock = () => {
        if (changes_disabled === false) {
            if (basket_count + unpushed_basket_count > 0) {
                if (push_basket_timer !== null) {
                    clearTimeout(push_basket_timer);
                }
                set_pbt(setTimeout(() => {
                    disable_changes(true);
                    add_to_basket(product, basket_count + unpushed_basket_count - 1, () => {
                        set_ubc(0);
                        disable_changes(false);
                    })
                }, 1000));
                set_ubc(unpushed_basket_count - 1)
            }
        }
    }

    let override = null;
    let in_basket = product.in_basket;

    if (product_id in stocks_override) {
        override = stocks_override[product_id];
        in_basket = override['basket']
    }

    if (current_prom_id !== null && available === 0) {
        return null;
    }
    if (!product) {
        return (
            <div className="product item loading">
               
            </div>
        );
    }

    return (
        <div className={`product item`}>
            {logged_in &&
                <div onClick={toggle_fave} data-tip data-for={`tt${product.id}`} className="heart_holder">
                    <FaHeart size={24} color={is_fave ? "#F016BB" : "#ccc"} />
                </div>
            }
            {rep.is_rep === true ?
                <div onClick={() => rep.show_modal_prod(product)} className="admin_menu">
                    <MdEdit size={20} />
                </div> : null
            }
            {logged_in && 
                <Tooltip
                    place="top"
                    type="error"
                    effect="float"
                    id={`tt${product.id}`}
                    >
                    {is_fave ? strings['remove-favourites'] : strings['add-to-faves']}
                </Tooltip>}

            {product.prom_price && <div className="prom-box">Special Offer</div>}

            {missing_img === true ? 
                <div style={{flex:1, alignSelf:'stretch', backgroundColor:'#ccc', width: 200, height: 200, justifyContent:'center', alignItems:'center', display: 'flex', borderRadius: 4}}>
                    <FaBox size={80} color='#444' />
                </div>
            :
            <div className="img-container">
                <img onLoad={() => set_missing_img(false)} onError={() => set_missing_img(true)} src={img_url + '/' + product.ean + '.jpg'} className="product-img" alt={product.description} />
            </div>
            }
            <div className="detail">
                <span className="title">{product.description} - {product.size}</span>
                <span className="ean">EAN: {product.ean}</span>
                <div style={{display: 'flex', alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                    <span className="case-size">{strings['case-size']}: {product.case}</span>
                    {logged_in && <Stock stock={available} />}
                </div>
            </div>
            

            {
                /*
                <div className="actions">
                <div>
                    {logged_in && product.is_wigig === false && available === 0 ?
                            <div onClick={toggle_follow}  className="row">
                                <FiMail size={24} color={is_following ? '#5169FA' : '#ccc'} style={{cursor:'pointer'}} />
                                <span style={{cursor: 'pointer'}}>{
                                    rep.is_rep === false || rep.as_customer === null ? 
                                        is_following ? strings['cancel-notification'] : strings['notify-me']
                                    :
                                        has_requested === false ?
                                            'Request for Customer'
                                        : has_requested + ' requested by customer'
                                }</span>
                            </div>
                        : <div className="row"/>
                    }
                </div>
            </div>
            */
            }
            
            {logged_in === true ?
                
                    !pricing ||!pricing.price ?
                    <div style={{fontSize: 14 , color:'red', margin: 10}}>Sorry - Out of Stock</div>
                    :
                    <div className="price_and_cart">
                        <ProductPrice pricing={pricing} case_size={product.case} />
                        
                        {basket_count + unpushed_basket_count > 0 ?
                            <div className="case_qty">
                                <FaMinus onClick={remove_from_basket_if_stock} size={20} color='grey' style={{cursor: 'pointer'}} />
                                   {basket_count + unpushed_basket_count}
                                <FaPlus onClick={add_to_basket_if_stock} size={20} color='#ff9800' style={{cursor: 'pointer'}} />
                            </div>
                            :
                            <div onClick={add_to_basket_if_stock} className="button">{strings['add']}</div>
                        }
                    </div>
                :
    
                <div className="product_login"><Link to='/login'>{strings['login']}</Link> {strings['or']} <Link to='create_account'>{strings['apply-for-account']}</Link> {strings['to-view-pricing']}</div>
            }
        </div>
    )
}

export default Product;