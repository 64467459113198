import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../context';
import Paginator from '../components/paginator';


const CheckFilter = props => {
    return (
        <div className={`filter${props.checked === true ? ' dirty' : ''}`}>
            <input type="checkbox" checked={props.checked} onChange={props.change} id={props.label + 'check'} />
            <label htmlFor={props.label + 'check'}>{props.label}</label>
        </div>
    )
}

const Option = props => {
    const { opt } = props;

    const select = (e, addremvoe) => {
        props.select(opt, addremvoe);
        e.stopPropagation();
    }

    return (
        <li>
            <div style={{display: 'inline'}} onClick={props.checked ? e => select(e,'remove') : e => select(e, 'add')}>
                <input type="checkbox" checked={props.checked} />
                <label>{opt.label}</label>
            </div>
        </li>
    )
}

const Filter = props => {
    const [timer, set_timer] = useState(null);
    const { onChange, value, level_2_values } = props;


    useEffect(() => {
        if (props.is_open !== true && timer !== null) {
            clearTimeout(timer);
        }
    })

    const clear = e => {
        e.cancelBubble = true;
        e.stopPropagation();
        onChange('clear');
    }

    const select = (e, addremove, level) => {
        onChange(e, addremove, level);
    }

    const away = () => {
        if (timer !== null) {
            clearTimeout(timer);
        }
        set_timer(setTimeout(props.close, 300));
    }

    const to = () => {
        if (timer !== null) {
            clearTimeout(timer);
        }
    }

    const { is_open } = props;
    const has_value = (value !== false || (level_2_values  !== false && level_2_values !== undefined));

    return (
        <div onClick={!is_open ? props.open : props.close} className={`filter${is_open ? ' open priority' : ' closed'}${has_value === true && props.hide_tick !== true ? ' dirty' : ''}`}>
            <div className="core">
                <span className="label">{props.label}</span>
            </div>
            {is_open && <div onMouseOver={to} onMouseOut={away} onClick={(e => e.stopPropagation())} className="expanded">
                {has_value && props.can_clear === true && props.hide_tick !== true ?
                    <div className="clear" onClick={clear}>Clear</div>
                    :
                    props.hide_tick !== true && 
                    <div className="clear" style={{cursor: 'auto'}}>Nothing selected</div>
                }
                <div className="options">
                    <ul>
                        {props.options.map((x,i) => {
                            if (!x.options) {
                                return (
                                    <Option key={i} checked={value !== false && value.indexOf(x.value) !== -1} select={(val, addremove) => select(val, addremove)} id={props.label + i} opt={x} />
                                )
                            } else {
                                return (
                                    <div key={i} className="option-group">
                                        <span className="title">{x.label}</span>
                                        <Option checked={value !== false && value.indexOf(x.value) !== -1} opt={{label: 'All ' + x.label, value: x.value}} select={(val, addremove) => select(val, addremove, 1)} />
                                        {
                                            x.options.map((o,i) => (<Option checked={level_2_values !== false && level_2_values.indexOf(o.value) !== -1} select={(val, addremove) => select(val, addremove, 2)} id={props.label + x.label + i} opt={o} />))
                                        }
                                    </div>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>}
        </div>
    )
}


const SortFilter = props => {
    const { shop } = useContext(AppContext);
    const { product_filters } = shop;

    
    const options = [
        {
            label: 'A-Z',
            value: 'name'
        },{
            label: 'Best Sellers',
            value: 'popularity'
        }
    ]

    const handleChange = e => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        product_filters.set_sort(e.value);
    }
    
    return (
        <Filter
            label={"Sort by"}
            default_value={{label: 'A-Z', value: 'name'}}
            options={options}
            onChange={handleChange}
            close={props.close}
            open={props.open}
            is_open={props.is_open}
            can_clear={false}
            hide_tick
            value={[product_filters.filters.sort_by]}
            />

    )
}

const PromotionFilter = props => {
    const { promotions, shop } = useContext(AppContext);
    const [options, set_options] = useState([])

    useEffect(() => {
        const opts = promotions.map(x => ({value: x.id, label: x.name}))
        set_options(opts);
    }, [promotions])

    const handleChange = (e, addremove) => {
        if (e === 'clear') {
            window.scrollTo({ top: 60, behavior: 'smooth' });
            shop.product_filters.clear_multi('promotions');
        } else {
            if (addremove === 'add') {
                window.scrollTo({ top: 60, behavior: 'smooth' });
                shop.product_filters.add_promotion(e.value);
            } else {
                window.scrollTo({ top: 60, behavior: 'smooth' });
                shop.product_filters.remove_promotion(e.value);
            }
        }
    }

    return (
        <Filter
            label="Promotions"
            can_clear={true}
            options={options}
            onChange={handleChange}
            open={props.open}
            close={props.close}
            is_open={props.is_open}
            value={shop.product_filters.filters.promotions}
            />
    )
}

const CategoryFilter = props => {
    const { category_map, shop } = useContext(AppContext);
    const { product_filters } = shop;

    const handleChange = (e, addremove, level) => {
        if (e === 'clear') {
            window.scrollTo({ top: 60, behavior: 'smooth' });
            product_filters.clear_multi('category_ones');
            product_filters.clear_multi('category_twos');

        } else {
            if (level === 1) {
                if (addremove === 'add') {
                    window.scrollTo({ top: 60, behavior: 'smooth' });
                    product_filters.add_category_one(e.value);
                } else {
                    window.scrollTo({ top: 60, behavior: 'smooth' });
                    product_filters.remove_category_one(e.value);
                }
            } else {
                if (addremove === 'add') {
                    window.scrollTo({ top: 60, behavior: 'smooth' });
                    product_filters.add_category_two(e.value);
                } else {
                    window.scrollTo({ top: 60, behavior: 'smooth' });
                    product_filters.remove_category_two(e.value);
                }
            }
        }
    }
    
    return (
        <Filter
            label="Category"
            options={category_map}
            can_clear={true}
            open={props.open}
            is_open={props.is_open}
            onChange={handleChange}
            close={props.close}
            value={product_filters.filters.category_ones}
            level_2_values={product_filters.filters.category_twos}
            />
    ) 
}

const FavesFilter = props => {
    const { shop } = useContext(AppContext);
    const { product_filters } = shop;

    const set_faves_only = f => {
        window.scrollTo({ top: 60, behavior: 'smooth' });
        product_filters.set_faves_only(f);
    }
    
    return (
        <CheckFilter checked={product_filters.filters.faves_only} change={() => set_faves_only(!product_filters.filters.faves_only)} label="Favourites Only" />
    )

}

const BrandFilter = props => {
    const { brands, shop } = useContext(AppContext);
    const { product_filters } = shop;

    const handleChange = (e, addremove) => {
        if (e === 'clear') {
            window.scrollTo({ top: 60, behavior: 'smooth' });
            product_filters.clear_multi('brands');
        }
        if (addremove === 'add') {
            window.scrollTo({ top: 60, behavior: 'smooth' });
            product_filters.add_brand(e.value);
        } else {
            window.scrollTo({ top: 60, behavior: 'smooth' });
            product_filters.remove_brand(e.value);
        }
    }

    return (
        <Filter
            label="Brand"
            can_clear={true}
            open={props.open}
            is_open={props.is_open}
            options={brands.map((b,i) => ({label: b, value: b}))}
            onChange={handleChange}
            close={props.close}
            value={product_filters.filters.brands}     
            />
    )
}

const ViewTypeFilter = props => {
    const { shop_view_type, set_shop_view_type } = useContext(AppContext);

    const handleChange = e => {
        set_shop_view_type(e.value);
        props.close()
    }

    return (
        <Filter
            label="View Type"
            can_clear={false}
            open={props.open}
            is_open={props.is_open}
            options={[{label: 'Grid', value:'grid'}, {label:'Table', value: 'table'}]}
            onChange={handleChange}
            close={props.close}
            value={[shop_view_type]}
            default_value={['grid']}
            hide_tick
            />
    )
}

const filters_is_set = (f) => f.in_stock !== false || f.faves_only !== false || f.brands !== false || f.category_ones !== false || f.category_twos !== false || f.promotions !== false || f.sort_by !== 'name' || f.search_term !== null || f.offset > 0;
const Menu = props => {
    const { shop, logged_in, rep } = useContext(AppContext);
    const { filters, filter_tick } = shop.product_filters;
    const [currently_open, set_co] = useState(null);
    const [is_set, set_is_set] = useState(false);


    useEffect(() => {
        const isset = filters_is_set(filters);
        if (isset !== is_set) {
            set_is_set(filters_is_set(filters));
        }
    }, [filters, filter_tick])

    const close = id => {
        if (currently_open === id) {
            set_co(null);
        }
    }

    return (
        <div className="top_menu">
            <div className="filters">
                <SortFilter      open={() => set_co(1)} is_open={currently_open === 1} close={() => close(1)} />
                <CategoryFilter  open={() => set_co(3)} is_open={currently_open === 3} close={() => close(3)} />
                <BrandFilter     open={() => set_co(6)} is_open={currently_open === 6} close={() => close(6)} />
                <PromotionFilter open={() => set_co(2)} is_open={currently_open === 2} close={() => close(2)} />
                {logged_in && <FavesFilter     open={() => set_co(5)} is_open={currently_open === 5} close={() => close(5)} />}
                {is_set && <div onClick={shop.product_filters.clear_all} className="clear_all">Clear All</div>}
                {rep.is_rep && <ViewTypeFilter open={() => set_co(8)} is_open={currently_open === 8} close={() => close(8)} />} 
            </div>
            <div className="paginator">
                <Paginator />
            </div>
        </div>
    )
}

export default Menu;